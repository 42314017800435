import React from 'react';

interface IBookCategoryHeaderProps {
  title: string;
}
const BookCategoryHeader: React.FC<IBookCategoryHeaderProps> = ({ title }) => {
  return (
    <div className='bookCategoryHeader'>
      <label>
        <button className='bookCategoryMark' />
        {title}
      </label>
    </div>
  );
};

export default BookCategoryHeader;
