import React, { useEffect, useState } from 'react';
//, { useState, useEffect, useRef } from "react";
//import { Text, DefaultButton } from "@fluentui/react";
//import { _dataProvider } from '../dataProvider/DataProvider';
//import { PromptPickGroup } from './Dialogues';
import { DefaultButton } from '@fluentui/react';
import i18n from '../i18n';
import { _dataProvider } from '../dataProvider/DataProvider';

interface IJoinProps {
  signInState: boolean;
}

const Join = (props: IJoinProps) => {
  const [code, setCode] = useState('');

  const processCode = () => {
    if (!props.signInState) {
      // user not signed in yet, header should be prompting the user to register or sign in
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    let base64 = urlParams.get('code');
    if (!base64) {
      // encodeURIComponent to convert chinese character into ascII
      base64 = btoa(encodeURIComponent(code));
    }
    if (base64) {
      const decode = decodeURIComponent(atob(base64));
      if (decode.startsWith('!contact!')) {
        const pa = decode.split('!');
        if (pa.length < 4) {
          console.log('contact info wrong!');
          return;
        }
        //const card = pa.slice(2);
        const uniqueId = pa[2];

        _dataProvider.createOneOnOneGroup(uniqueId).then((newGroup: any) => {
          const txt = i18n.t('AcceptInvite');
          const msg = txt.replace('NNN', pa[3]);
          _dataProvider.postGroupMessage(newGroup.studyGroupId, msg).then(() => {
            window.location.href = `/?code=${newGroup.studyGroupId}#/group`;
          });
        });
      } else if (decode.startsWith('launch?code=')) {
        const launchCode = decode.slice(12);
        _dataProvider.launchUrl(launchCode).then((result) => {
          if (!result) {
            return;
          }
          if (result?.cmd === 'joinStudyGroup') {
            console.log('join: ', result, result.data);

            const msg = result?.data?.status ? 'Chatroom.JoinSuccess' : 'Chatroom.JoinWaiting';
            alert(i18n.t(msg));

            if (result?.data?.status) {
              window.location.href = `/?code=${result?.data?.value}#/group`;
            } else {
              //waiting for approval
              window.location.href = '/appDownload';
            }
          }
        });
      }
    }
  };

  useEffect(processCode, [props.signInState]);

  return (
    <div style={{ marginLeft: '100px' }}>
      <input
        style={{ width: '30%', marginTop: '200px', marginBottom: '20px', padding: '10px' }}
        placeholder={'please enter a code'}
        value={code}
        onChange={(e) => {
          setCode(e.target.value);
        }}
      />
      <br />
      <DefaultButton
        style={{
          borderRadius: '25px',
          height: '30px'
        }}
        onClick={processCode}>
        {i18n.t('submit')}
      </DefaultButton>
    </div>
  );
};

export default Join;
