import React from 'react';
//import Footer from "./Footer";
import './ClassRegistration.css';
import Login from './Login';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
//import axios from "axios";
import provincesAndCities from './provincesAndCities.json';
import { _dataProvider } from '../dataProvider/DataProvider';
import { DefaultButton, ChoiceGroup, IChoiceGroupOption, TextField, Dropdown, IDropdownOption } from '@fluentui/react';
import { getJwtObj } from '../SharedCommon/utils';
import i18n from '../i18n';

interface IClassRegistrationProps {
  contentHeight: string;

  signInState: boolean;
  onSignInOut: (signInState: boolean) => void;
}

interface IClassRegistrationState {
  isUserLoggedIn: boolean;
  open: boolean;
  showSecondStep: boolean;
  registeredName: string;
  registeredEmail: string;
  realName: string;
  phone: string;
  email: string;
  weChat: string;
  gender: string;
  education: string;
  yearsAsChristian: string;
  careExperience: string;
  trainingExpectation: string;
  acknowledge: boolean[];
  showConfirmation: boolean;
  submissionData: any;
  isRegistered: boolean;
  loginEmail: string;
  isAcknowledged: boolean;
  acknowledgeOption: string;
  focusedSelect: string | null;
  provinceCity: string;
  provincesAndCities: { [key: string]: string[] };
  selectedProvince: string;
  selectedCity: string;
  cities: string[];
  trainingId: string;
  joinToken: string;
  submitErrorMessage: string;
  groupName: string;
  trainingName: string;
  canJoin: boolean;
  fieldErrors: {
    realName: boolean;
    weChat: boolean;
    gender: boolean;
    education: boolean;
    provinceCity: boolean;
    yearsAsChristian: boolean;
  };
}

/* interface IProvinceCity {
  name: string;
  cities: string[];
} */

const buttonStyles = {
  root: {
    marginRight: '20px',
    backgroundColor: '#007bff',
    color: 'white',
    width: '200px',
    height: '50px',
    borderRadius: '250px',
    ':hover': {
      backgroundColor: '#0056b3'
    }
  },
  rootDisabled: {
    backgroundColor: '#ccc',
    color: '#666',
    ':hover': {
      backgroundColor: '#ccc'
    }
  }
};

const options: IChoiceGroupOption[] = [
  { key: 'Yes', text: '同意' },
  { key: 'No', text: '不同意' }
];

const genderOptions: IDropdownOption[] = [
  { key: '男', text: '男' },
  { key: '女', text: '女' }
];

const educationOptions: IDropdownOption[] = [
  { key: '初中及以下', text: '初中及以下' },
  { key: '高中/中专', text: '高中/中专' },
  { key: '大专/大学', text: '大专/大学' },
  { key: '研究生及以上', text: '研究生及以上' }
];

const yearsAsChristianOptions: IDropdownOption[] = [
  { key: '不到2年', text: '不到2年' },
  { key: '2-10年', text: '2-10年' },
  { key: '10年以上', text: '10年以上' }
];

class ClassRegistration extends React.Component<IClassRegistrationProps, IClassRegistrationState> {
  constructor(props: IClassRegistrationProps) {
    super(props);
    this.state = {
      isUserLoggedIn: props.signInState,
      open: false,
      showSecondStep: false,
      registeredName: '',
      registeredEmail: '',
      realName: '',
      phone: '',
      email: '',
      weChat: '',
      gender: '',
      education: '',
      yearsAsChristian: '',
      careExperience: '',
      trainingExpectation: '',
      acknowledge: [false, false, false, false, false, false],
      showConfirmation: false,
      submissionData: null,
      isRegistered: false,
      loginEmail: '',
      isAcknowledged: false,
      acknowledgeOption: '',
      focusedSelect: null,
      provinceCity: '',
      provincesAndCities: provincesAndCities,
      selectedProvince: '',
      selectedCity: '',
      cities: [],
      trainingId: '',
      joinToken: '',
      submitErrorMessage: '',
      groupName: '',
      trainingName: '',
      canJoin: false,
      fieldErrors: {
        realName: false,
        weChat: false,
        gender: false,
        education: false,
        provinceCity: false,
        yearsAsChristian: false
      }
    };
  }

  componentDidMount() {
    console.log('Component did mount!');

    this.handleTokenLoginAndRedirect();
    if (!this.state.isUserLoggedIn) {
      this.setState({ open: true });
    } else {
      this.handleDetailsClick();

      const url = window.location.href;
      const parts = url.split('/');
      const trainingId = Number(parts[parts.length - 2]);
      const joinToken = parts[parts.length - 1].split('?')[0];

      const jwt = getJwtObj();
      const loginId = jwt.userId !== -1 ? jwt.loginId : '未知邮箱';
      const trainingIdStr = trainingId.toString();
      const orgId = _dataProvider.getOrgId();
      if (!orgId) {
        console.error('无法获取 orgId');
        return;
      }
      this.setState({ trainingId: trainingIdStr, joinToken });

      _dataProvider
        .checkUserRegistration(orgId.toString(), trainingIdStr, joinToken)
        .then((response) => {
          if (response.error) {
            this.setState({
              submitErrorMessage: response.error
            });
            return;
          }
          const canJoinResult = response.canJoin;
          _dataProvider
            .getTrainingUser(trainingId)
            .then((user) => {
              if (user && Object.keys(user).length > 0) {
                // If user info exits
                if (canJoinResult) {
                  // If canJoin is true，user can register
                  this.setState({
                    trainingId: trainingIdStr,
                    canJoin: canJoinResult || false
                  });
                } else {
                  this.setState({
                    canJoin: canJoinResult || false,
                    submitErrorMessage: `报名失败\n小鸽子用户 ${loginId} 此前已经报名过该培训`
                  });
                }
              } else {
                // If user info does not exits, user can register
                this.setState({
                  trainingId: trainingIdStr,
                  canJoin: canJoinResult || false
                });
              }
            })
            .catch((error) => {
              console.error('获取用户报名信息失败:', error);
              // IMPORTANT: getTrainingUser does not has specific error for user info not found
              // If getTrainingUser failed，means not info，user can register
              this.setState({
                trainingId: trainingIdStr,
                canJoin: canJoinResult || false
              });
            });
        })
        .catch((error) => {
          console.error('检查用户报名状态失败:', error);
          this.setState({
            submitErrorMessage: i18n.t('SubmitError')
          });
        });
    }
  }

  handleTokenLoginAndRedirect = () => {
    const hash = window.location.hash;
    const hashParams = new URLSearchParams(hash.substring(hash.indexOf('?')));
    const token = hashParams.get('token');

    if (token) {
      localStorage.setItem('access_token', token);
      hashParams.delete('token');
      const newHash = hash.substring(0, hash.indexOf('?')) + (hashParams.toString() ? '?' + hashParams.toString() : '');
      window.location.hash = newHash;
      window.location.reload();
    }
  };

  componentDidUpdate(prevProps: IClassRegistrationProps) {
    if (prevProps.signInState !== this.props.signInState) {
      this.setState({ isUserLoggedIn: this.props.signInState });
    }
  }

  handleLoginSuccess = () => {
    this.setState({
      isUserLoggedIn: true,
      showSecondStep: true
    });
    this.props.onSignInOut(true);
    // Using a dumb way to update sign out UI. For now :(
    window.location.reload();
  };

  handleDetailsClick = () => {
    if (this.state.isUserLoggedIn) {
      this.setState({ showSecondStep: true });
    } else {
      this.setState({ open: true });
    }
  };

  onChoiceChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    if (option) {
      this.setState({ acknowledgeOption: option.key });
    }
  };

  handleSubmit = async () => {
    const {
      realName,
      phone,
      email,
      weChat,
      gender,
      education,
      provinceCity,
      yearsAsChristian,
      careExperience,
      trainingExpectation
    } = this.state;

    const submissionData = {
      realName: realName.trim(),
      phone,
      email: email.trim(),
      weChat: weChat.trim(),
      gender,
      education,
      provinceCity,
      yearsAsChristian,
      careExperience,
      trainingExpectation
    };

    const errors = {
      realName: !submissionData.realName,
      weChat: !submissionData.weChat,
      gender: !gender,
      education: !education,
      provinceCity: !provinceCity,
      yearsAsChristian: !yearsAsChristian
    };

    this.setState({
      submissionData,
      fieldErrors: errors
    });

    const allFieldsValid = Object.values(errors).every((error) => !error);
    if (allFieldsValid) {
      this.setState({
        showConfirmation: true,
        showSecondStep: false
      });
    }
  };

  handleBackToEdit = () => {
    this.setState({ showConfirmation: false, showSecondStep: true });
  };

  handleFinalSubmit = async () => {
    const { submissionData, trainingId, joinToken } = this.state;

    const updateSuccessState = (groupName = '', trainingName = '') => {
      this.setState({
        isRegistered: true,
        loginEmail: this.state.email,
        groupName: groupName,
        trainingName: trainingName,
        submitErrorMessage: ''
      });
    };

    try {
      const dataToSubmit = JSON.stringify(submissionData);
      const submitResult = await _dataProvider.submitRegistrationData(dataToSubmit, trainingId);
      console.log('jointokne', joinToken);

      if (submitResult) {
        // Always attempt to join the training group after successful registration
        const joinResult = await _dataProvider.joinTrainingGroup(trainingId, joinToken);

        if (joinResult && joinResult.groupName) {
          updateSuccessState(joinResult.groupName, joinResult.trainingName);
        } else {
          throw new Error('加入小组失败');
        }
      } else {
        throw new Error('提交报名信息失败');
      }
    } catch (error) {
      let errorMessage = i18n.t('RegistrationNotStartedError');

      if (error.response && error.response.data && error.response.data.reason) {
        switch (error.response.data.reason) {
          case 'TrainingStudyGroupNotFound':
            errorMessage = i18n.t('MissingGroupError');
            break;
          default:
            break;
        }
      }
      this.setState({
        submitErrorMessage: errorMessage
      });
    }
  };
  checkAllRequiredFieldsFilled = () => {
    const { acknowledgeOption } = this.state;

    const allFilled = acknowledgeOption === 'Yes';

    return allFilled;
  };

  handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const target = event.target;
    const value = target instanceof HTMLInputElement && target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value } as any);
  };

  handleGenderChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    this.setState((prevState) => ({
      gender: option ? (option.key as string) : '',
      fieldErrors: {
        ...prevState.fieldErrors,
        gender: !option
      }
    }));
  };

  renderIntroPage = () => (
    <div className='intro-page-container'>
      <div className='intro-page-title'>如何参加[實用關懷]課程？</div>
      <div className='intro-page-content'>
        {'關懷是一堂基督徒的必修課。。。\n一堂很多人認為沒什麼必要。。。\n也要彼此相愛及愛人如己。。。\n人提供知識。。。\n鄰舍，弟兄姊妹等關係上\n更多基督徒接受準備後。。。'
          .split('\n')
          .map((line, index) => (
            <p key={index}>{line}</p>
          ))}
      </div>
      <DefaultButton onClick={this.handleDetailsClick} text='详细信息' />
    </div>
  );

  handleSelectFocus = (event: React.FocusEvent<HTMLSelectElement>) => {
    const name = event.target.name;
    this.setState({ focusedSelect: name });
  };

  handleSelectBlur = () => {
    this.setState({ focusedSelect: null });
  };

  handleProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const province = event.target.value;
    const cities = this.state.provincesAndCities[province] || [];
    this.setState({ selectedProvince: province, cities });
  };

  handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCity = event.target.value;
    const { selectedProvince } = this.state;
    const provinceCity = `${selectedProvince} - ${selectedCity}`;
    this.setState({ selectedCity, provinceCity });
  };

  handleEducationChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    this.setState((prevState) => ({
      education: option ? (option.key as string) : '',
      fieldErrors: {
        ...prevState.fieldErrors,
        education: !option
      }
    }));
  };

  handleYearsAsChristianChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    this.setState((prevState) => ({
      yearsAsChristian: option ? (option.key as string) : '',
      fieldErrors: {
        ...prevState.fieldErrors,
        yearsAsChristian: !option
      }
    }));
  };

  handleProvinceDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    const province = option ? (option.key as string) : '';
    const cities = this.state.provincesAndCities[province] || [];
    this.setState((prevState) => ({
      selectedProvince: province,
      cities,
      selectedCity: '',
      fieldErrors: {
        ...prevState.fieldErrors,
        provinceCity: !province
      }
    }));
  };

  handleCityDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    const selectedCity = option ? (option.key as string) : '';
    this.setState((prevState) => ({
      selectedCity,
      provinceCity: `${this.state.selectedProvince} - ${selectedCity}`,
      fieldErrors: {
        ...prevState.fieldErrors,
        provinceCity: !selectedCity
      }
    }));
  };

  handleTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const trimmedValue = newValue?.trim();

    this.setState(
      (prevState) =>
        ({
          [name]: trimmedValue,
          fieldErrors: {
            ...prevState.fieldErrors,
            [name]: trimmedValue === ''
          }
        } as any)
    );
  };

  renderConfirmationPage = () => {
    const { submissionData } = this.state;

    return (
      <div className='registration-success-container'>
        <h2>报名信息确认</h2>
        {submissionData && (
          <>
            <p>
              <strong>真实姓名</strong>: {submissionData.realName}
            </p>
            <p>
              <strong>联系电话</strong>: {submissionData.phone}
            </p>
            <p>
              <strong>联系电子邮件</strong>: {submissionData.email}
            </p>
            <p>
              <strong>微信号</strong>: {submissionData.weChat}
            </p>
            <p>
              <strong>性别</strong>: {submissionData.gender}
            </p>
            <p>
              <strong>学历</strong>: {submissionData.education}
            </p>
            <p>
              <strong>省份城市</strong>: {submissionData.provinceCity}
            </p>
            <p>
              <strong>信主年数</strong>: {submissionData.yearsAsChristian}
            </p>
            <p>
              <strong>关怀经验</strong>: {submissionData.careExperience}
            </p>
            <p>
              <strong>培训期望</strong>: {submissionData.trainingExpectation}
            </p>
          </>
        )}
        <DefaultButton onClick={this.handleBackToEdit} styles={buttonStyles} text='返回修改' />
        <DefaultButton onClick={this.handleFinalSubmit} styles={buttonStyles} text='提交确认' />
      </div>
    );
  };

  render() {
    const {
      showSecondStep,
      showConfirmation,
      isRegistered,
      focusedSelect,
      groupName,
      trainingName,
      submitErrorMessage
    } = this.state;
    const jwt = getJwtObj();
    const loginId = jwt.userId !== -1 ? jwt.loginId : '未知邮箱';

    if (submitErrorMessage !== '') {
      return (
        <div className='error-message'>
          {submitErrorMessage.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < submitErrorMessage.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      );
    }
    if (isRegistered) {
      return (
        <div className='success-container'>
          <h2>报名成功！</h2>
          <p>
            欢迎你参加“<strong>{trainingName}</strong>
            ”。请打开移动端的小鸽子App，用 “<strong>{loginId}</strong>
            ”登录，你会在“团契”页面看到一个专门为这期培训设置的学习小组“
            <strong>{groupName}</strong>
            ”，你可以在这个小组里与其他学员和老师交流。我们将来的线上课程也在这个小组里进行。
          </p>
          <p>期待和你一起学习！</p>
        </div>
      );
    } else if (showConfirmation) {
      return this.renderConfirmationPage();
    } else if (showSecondStep) {
      return (
        <div className='registration-success-container'>
          <div className='step-two-title'>
            <h2>第二步：提交报名信息</h2>
          </div>
          <div className='input-container fluentInput'>
            <label htmlFor='realName'>1. {i18n.t('registerFields.name')}</label>
            {this.state.fieldErrors.realName && (
              <div className='missing-filed-message'>{i18n.t('MissingRequiredField')}</div>
            )}
            <TextField
              id='realName'
              name='realName'
              placeholder={i18n.t('registerPlacehold.name')}
              value={this.state.realName}
              onChange={this.handleTextFieldChange}
              styles={{
                fieldGroup: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px'
                },
                field: { fontSize: '1.4em' }
              }}
            />
          </div>

          <div className='input-container fluentInput'>
            <label htmlFor='phone'>2. {i18n.t('registerFields.phone')}</label>
            <TextField
              id='phone'
              name='phone'
              placeholder={i18n.t('registerPlacehold.phone')}
              value={this.state.phone}
              onChange={this.handleTextFieldChange}
              styles={{
                fieldGroup: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px'
                },
                field: { fontSize: '1.4em' }
              }}
            />
          </div>

          <div className='input-container fluentInput'>
            <label htmlFor='email'>3. {i18n.t('registerFields.email')}</label>
            <TextField
              id='email'
              type='email'
              name='email'
              placeholder={i18n.t('registerPlacehold.email')}
              value={this.state.email}
              onChange={this.handleTextFieldChange}
              styles={{
                fieldGroup: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px'
                },
                field: { fontSize: '1.4em' }
              }}
            />
          </div>

          <div className='input-container fluentInput'>
            <label htmlFor='weChat'>4. {i18n.t('registerFields.weChat')}</label>
            {this.state.fieldErrors.weChat && (
              <div className='missing-filed-message'>{i18n.t('MissingRequiredField')}</div>
            )}
            <TextField
              id='weChat'
              name='weChat'
              placeholder={i18n.t('registerPlacehold.weChat')}
              value={this.state.weChat}
              onChange={this.handleTextFieldChange}
              styles={{
                fieldGroup: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px'
                },
                field: { fontSize: '1.4em' }
              }}
            />
          </div>

          <div className={`input-container fluentInput ${focusedSelect === 'gender' ? 'open' : ''}`}>
            <label htmlFor='gender'>5. {i18n.t('registerFields.gender')}</label>
            {this.state.fieldErrors.gender && (
              <div className='missing-filed-message'>{i18n.t('MissingRequiredField')}</div>
            )}
            <Dropdown
              selectedKey={this.state.gender}
              onChange={this.handleGenderChange}
              placeholder={i18n.t('registerPlacehold.gender')}
              options={genderOptions}
              calloutProps={{ calloutMaxWidth: 200 }}
              styles={{
                caretDownWrapper: {
                  selectors: {
                    '& .ms-Dropdown-caretDown': {
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: '20px'
                    }
                  }
                },
                title: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px',
                  padding: '5px 0'
                },
                dropdown: {
                  selectors: {
                    '::placeholder': {
                      textAlign: 'center'
                    },
                    '.ms-Dropdown-title': {
                      textAlign: 'center',
                      fontSize: '1.4em'
                    }
                  }
                }
              }}
            />
          </div>

          <div className={`input-container fluentInput ${focusedSelect === 'education' ? 'open' : ''}`}>
            <label htmlFor='education'>6. {i18n.t('registerFields.education')}</label>
            {this.state.fieldErrors.education && (
              <div className='missing-filed-message'>{i18n.t('MissingRequiredField')}</div>
            )}
            <Dropdown
              selectedKey={this.state.education}
              onChange={this.handleEducationChange}
              placeholder={i18n.t('registerPlacehold.education')}
              options={educationOptions}
              calloutProps={{ calloutMaxWidth: 200 }}
              styles={{
                caretDownWrapper: {
                  selectors: {
                    '& .ms-Dropdown-caretDown': {
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: '20px'
                    }
                  }
                },
                title: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px',
                  padding: '5px 0'
                },
                dropdown: {
                  selectors: {
                    '::placeholder': {
                      textAlign: 'center'
                    },
                    '.ms-Dropdown-title': {
                      textAlign: 'center',
                      fontSize: '1.4em'
                    }
                  }
                }
              }}
            />
          </div>

          <div className={`input-container fluentInput ${focusedSelect === 'provinceCity' ? 'open' : ''}`}>
            <label htmlFor='provinceCity'>7. {i18n.t('registerFields.provinceCity')}</label>
            {this.state.fieldErrors.provinceCity && (
              <div className='missing-filed-message'>{i18n.t('MissingRequiredField')}</div>
            )}
            <Dropdown
              selectedKey={this.state.selectedProvince}
              onChange={this.handleProvinceDropdownChange}
              placeholder={i18n.t('registerPlacehold.province')}
              options={Object.keys(this.state.provincesAndCities).map((key) => ({ key, text: key }))}
              calloutProps={{ calloutMaxWidth: 200 }}
              styles={{
                caretDownWrapper: {
                  selectors: {
                    '& .ms-Dropdown-caretDown': {
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: '20px'
                    }
                  }
                },
                title: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px',
                  padding: '5px 0'
                },
                dropdown: {
                  selectors: {
                    '::placeholder': {
                      textAlign: 'center'
                    },
                    '.ms-Dropdown-title': {
                      textAlign: 'center',
                      fontSize: '1.4em'
                    }
                  }
                }
              }}
            />
            {this.state.selectedProvince && (
              <Dropdown
                selectedKey={this.state.selectedCity}
                onChange={this.handleCityDropdownChange}
                placeholder={i18n.t('registerPlacehold.city')}
                options={this.state.cities.map((city) => ({
                  key: city,
                  text: city
                }))}
                calloutProps={{ calloutMaxWidth: 200 }}
                styles={{
                  caretDownWrapper: {
                    selectors: {
                      '& .ms-Dropdown-caretDown': {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '20px'
                      }
                    }
                  },
                  title: {
                    borderRadius: '10px',
                    height: 'auto',
                    minHeight: '40px',
                    padding: '5px 0'
                  },
                  dropdown: {
                    selectors: {
                      '::placeholder': {
                        textAlign: 'center'
                      },
                      '.ms-Dropdown-title': {
                        textAlign: 'center',
                        fontSize: '1.4em'
                      }
                    }
                  }
                }}
              />
            )}
          </div>

          <div className={`input-container fluentInput ${focusedSelect === 'yearsAsChristian' ? 'open' : ''}`}>
            <label htmlFor='yearsAsChristian'>8. {i18n.t('registerFields.yearsAsChristian')}</label>
            {this.state.fieldErrors.yearsAsChristian && (
              <div className='missing-filed-message'>{i18n.t('MissingRequiredField')}</div>
            )}
            <Dropdown
              selectedKey={this.state.yearsAsChristian}
              onChange={this.handleYearsAsChristianChange}
              placeholder={i18n.t('registerPlacehold.yearsAsChristian')}
              options={yearsAsChristianOptions}
              calloutProps={{ calloutMaxWidth: 200 }}
              styles={{
                caretDownWrapper: {
                  selectors: {
                    '& .ms-Dropdown-caretDown': {
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: '20px'
                    }
                  }
                },
                title: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px',
                  padding: '5px 0'
                },
                dropdown: {
                  selectors: {
                    '::placeholder': {
                      textAlign: 'center'
                    },
                    '.ms-Dropdown-title': {
                      textAlign: 'center',
                      fontSize: '1.4em'
                    }
                  }
                }
              }}
            />
          </div>

          <div className='input-container fluentInput'>
            <label htmlFor='careExperience'>9. {i18n.t('registerFields.careExperience')}</label>
            <TextField
              id='careExperience'
              name='careExperience'
              placeholder={i18n.t('registerPlacehold.careExperience')}
              multiline
              rows={5}
              value={this.state.careExperience}
              onChange={this.handleTextFieldChange}
              styles={{
                fieldGroup: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px'
                },
                field: {
                  textAlign: 'left !important',
                  fontSize: '1.4em'
                }
              }}
            />
          </div>

          <div className='input-container fluentInput'>
            <label htmlFor='trainingExpectation'>10. {i18n.t('registerFields.trainingExpectation')}</label>
            <TextField
              id='trainingExpectation'
              name='trainingExpectation'
              placeholder={i18n.t('registerPlacehold.trainingExpectation')}
              multiline
              rows={5}
              value={this.state.trainingExpectation}
              onChange={this.handleTextFieldChange}
              styles={{
                fieldGroup: {
                  borderRadius: '10px',
                  height: 'auto',
                  minHeight: '40px'
                },
                field: {
                  textAlign: 'left !important',
                  fontSize: '1.4em'
                }
              }}
            />
          </div>

          <div className='input-container step-two-input'>
            <label className='acknowledgment-title'>{i18n.t('DoYouNotice')}</label>
            <div className='acknowledgment-list'>
              <p>{i18n.t('notice.1')}</p>
              <p>{i18n.t('notice.3')}</p>
              <p>{i18n.t('notice.4')}</p>
              <p>{i18n.t('notice.5')}</p>
              <p>{i18n.t('notice.7')}</p>
            </div>
            <ChoiceGroup
              styles={{
                flexContainer: {
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  fontSize: '16px'
                }
              }}
              defaultSelectedKey='No'
              options={options}
              onChange={this.onChoiceChange}
              required={true}
            />
          </div>

          <div className='form-footer'>
            <DefaultButton
              onClick={this.handleSubmit}
              text='提交'
              styles={{
                ...buttonStyles,
                root: {
                  width: '200px',
                  height: '50px',
                  marginBottom: '100px' // the button cannot be shown on Safari on iOS device, this is a temporary hacky fix
                }
              }}
              disabled={!this.checkAllRequiredFieldsFilled()}
            />
          </div>
        </div>
      );
    }

    return (
      <div className='registration-page-container'>
        {/* {this.renderIntroPage()} */}
        <Popup open={this.state.open} closeOnDocumentClick onClose={() => this.setState({ open: false })} modal>
          <div className='modal-container'>
            <div className='modal-content'>
              <button className='close-button' onClick={() => this.setState({ open: false })}>
                &times;
              </button>
              <Login
                onSwitchToRegister={() => this.setState({ isUserLoggedIn: false })}
                onLoginSuccess={this.handleLoginSuccess}
              />
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}
export default ClassRegistration;
