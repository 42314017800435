/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native-web';

export default class Send extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false
    };
  }

  handleOnPress = () => {
    const { text, onSend } = this.props;
    if (text && onSend) {
      onSend({ text: text.trim() }, true);
    }
  };

  render() {
    const { renderSendIcon, renderSendHoverIcon, text } = this.props;
    const { isHovered } = this.state;
    const isDisabled = !text || text.trim().length === 0;

    if (renderSendIcon) {
      return (
        <TouchableOpacity
          testID='send'
          accessible
          accessibilityLabel='send'
          style={[styles.container, this.props.containerStyle, isDisabled && styles.disabled]}
          onPress={this.handleOnPress}
          disabled={isDisabled}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}>
          <View style={[styles.wrapper, this.props.wrapperStyle, isDisabled && styles.wrapperDisabled]}>
            {isHovered && !isDisabled && renderSendHoverIcon ? renderSendHoverIcon() : renderSendIcon()}
          </View>
        </TouchableOpacity>
      );
    }
    return null;
  }
}

const styles = StyleSheet.create({
  container: {
    height: 44,
    justifyContent: 'flex-end'
  },
  wrapper: {
    marginBottom: 10,
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  disabled: {
    opacity: 0.5
  },
  wrapperDisabled: {
    opacity: 0.5
  }
});

Send.defaultProps = {
  text: '',
  onSend: () => {},
  renderSendIcon: null,
  renderSendHoverIcon: null,
  containerStyle: {},
  wrapperStyle: {}
};

Send.propTypes = {
  text: PropTypes.string,
  onSend: PropTypes.func,
  renderSendIcon: PropTypes.func,
  renderSendHoverIcon: PropTypes.func,
  containerStyle: PropTypes.object,
  wrapperStyle: PropTypes.object
};
