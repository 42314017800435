import { IBook, IBookTable } from '../dataProvider/IBookTable';
import { IChapterContentAndAnswers } from '../dataProvider/IBookContent';
import { IAppSessionSettings } from '../dataProvider/IAppSessionSettings';
import { _dataProvider } from '../dataProvider/DataProvider';
import DiscoverBook from './DiscoverBook';
import TableOfContent from './TableOfContent';
import PhoneViewer from './PhoneViewer';
import Editor from './editor';
import BibleNavigation from './BibleNavigation';
import React from 'react';
import { phoneEmulatorTemplate, _smallScreen } from '../SharedCommon/utils';
import i18n from '../i18n';
import { IShowSearchContextType, ShowSearchBarContext } from '../ShowSearchBarContext';

interface IDiscoverStudyProps {
  saveSettings: any;
  signInState: boolean;
  settings: IAppSessionSettings;
  HeadClickCount: number;
}

interface IDiscoverStudyState {
  books: IBook[];
  bookTable?: IBookTable;
  bookContent?: IChapterContentAndAnswers;
  bookNotes?: string;
  templateUri: string;
  //bookDbId: number;  //TODO: will do this later.
  //  if we save the state between browser sesssions, as array index can
  //  change due to DB changes.
  bookIdx: number;
  chapterIdx: number;
  bibleData?: any;
  contentHeight: string;
  progress: string;
  //chapterFile: string;
}

class DiscoverStudy extends React.Component<IDiscoverStudyProps, IDiscoverStudyState> {
  private sessionIdx: number;
  private merge: boolean;
  static contextType = ShowSearchBarContext;

  constructor(props: IDiscoverStudyProps) {
    super(props);

    this.merge = false;
    this.sessionIdx = 0; // sessionIdx;
    //this is taken out of state, because its change doesn't need to redraw from the whole DiscoverStudy level
    //when the user clicks between days of the same chapter, that's handled within template.html
    //but we save this value, so that we can restore between browser sessions.

    const { sessionIdx, chapterIdx, merge } = props.settings;
    if (sessionIdx) {
      this.sessionIdx = sessionIdx!;
    }
    if (merge) {
      this.merge = merge!;
    }

    //todo: lang probably should be a settings --- but that will require a settings page later...
    this.state = {
      books: [],
      templateUri: phoneEmulatorTemplate,

      //todo: the folowing should be coming out of last session/settings
      //contentHeight: "92vh",
      contentHeight: `${window.innerHeight - 52}px`,
      progress: '',

      bookIdx: -1, //bookIdx,  //number;
      chapterIdx //chapterIdx //: number;
    };
  }

  componentDidMount() {
    console.log('DiscoverStudy componentDidMount: ', this.state.chapterIdx);
    this.setState({
      contentHeight: `${window.innerHeight - document!.getElementById('idHeaderMenuBar')!.clientHeight}px`
    });
    window.addEventListener('resize', this.updateDimensions);

    //refresh, as it could come from a book creation, and/or book title update
    this.load(true);

    const { setVisible } = this.context as IShowSearchContextType;
    setVisible(this.state.bookIdx === -1);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Hide search bar when book open
    if (prevState.bookIdx !== this.state.bookIdx) {
      const { setVisible } = this.context as IShowSearchContextType;
      setVisible(this.state.bookIdx === -1);
    }

    // Close book when user clicks the top header.
    if (prevProps.HeadClickCount !== this.props.HeadClickCount) {
      console.log('DiscoveryStudy: the top header is clicked, clear state');
      this.setState({
        bookIdx: -1,
        chapterIdx: -1,
        bookTable: undefined,
        bookContent: undefined,
        bibleData: undefined
      });
    }

    if (prevProps.signInState !== this.props.signInState) {
      if (!this.props.signInState) {
        this.setState(
          {
            books: [],
            bookTable: undefined,
            bookContent: undefined,
            bookIdx: -1,
            chapterIdx: -1,
            bibleData: undefined
          },
          () => {
            this.load(true);
          }
        );
      } else {
        this.load(true);
      }
    }

    if (prevState.bookIdx !== this.state.bookIdx) {
      const { setVisible } = this.context as IShowSearchContextType;
      setVisible(this.state.bookIdx === -1);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  shouldComponentUpdate(nextProps: IDiscoverStudyProps) {
    console.log('DiscoverStudy shouldComponentUpdate', this.state.chapterIdx);
    if (this.props.signInState !== nextProps.signInState) {
      if (!nextProps.signInState) {
        // signout
        const books: any = [];
        const bookTable = undefined;
        const bookContent = undefined;
        const bookIdx = -1; //bookIdx,  //number;
        const chapterIdx = -1; //chapterIdx //: number;
        const bibleData = undefined;
        this.setState({ books, bookTable, bookContent, bookIdx, chapterIdx, bibleData }, () => {
          //this._saveSettings();
          this.load(true); //there are books free that doesn't need a signin
        });
      } else {
        // sign in state
        this.load(true); // reload
      }
    } else if (this.props.HeadClickCount !== nextProps.HeadClickCount) {
      console.log('DiscoveryStudy: the top header is clicked, clear state', this.state.bookTable);
      const bookIdx = -1;
      const chapterIdx = -1;
      const bookTable = undefined;
      const bookContent = undefined;
      const bibleData = undefined;

      const { books } = this.state;
      this.sortBooks(books);

      this.setState({
        books,
        bookIdx,
        chapterIdx,
        bookTable,
        bookContent,
        bibleData
      });

      this.props.saveSettings({ name: '', chapterIdx });
    }

    return true;
  }

  private updateDimensions = () => {
    console.log('DiscoverStudy updateDimensions: ', window.innerHeight);
    this.setState({
      contentHeight: `${window.innerHeight - document!.getElementById('idHeaderMenuBar')!.clientHeight}px`
    });
  };

  private sortBooks(books: IBook[]) {
    //* sorting the book list
    const bookAccessTime = JSON.parse(localStorage.getItem('classOrder') || '{}');
    books.sort((a, b) => (bookAccessTime[b.name] || 0) - (bookAccessTime[a.name] || 0));
  }

  load = (refresh: boolean) => {
    //const books: IBook[] =
    console.log('Load: ', refresh, this.state.chapterIdx);
    _dataProvider.getBooks2(refresh).then((books: IBook[]) => {
      //* sorting the book list
      if (!books) {
        return;
      }
      this.sortBooks(books);

      if (_dataProvider.getOrgId() === 0) {
        books.unshift({
          name: '917',
          classId: 917, //TODONOW: 917, a class under mike.w.guo user...
          classNameChs: '圣经 Holy Bible',
          classNameCht: '圣经 Holy Bible',
          classNameEng: '圣经 Holy Bible'
        });
      }

      this.setState({ books }, () => {
        if (books.length === 0) {
          return;
        }

        const { chapterIdx } = this.state;
        const { name } = this.props.settings;
        const bookIdx = books.findIndex((book) => book.name === name);

        console.log('navigate to last book?: ', name, bookIdx, chapterIdx, this.props.HeadClickCount);

        if (this.props.HeadClickCount === 0) {
          // only go back to the last place of study at launch
          //don't do this after launch, and then signout...
          if (bookIdx >= 0 && this.props.signInState) {
            this._setBook(bookIdx, chapterIdx);
          }
        }
      });
    });
  };

  private _setBible = (bibleData: any): void => {
    this.setState({ bibleData });
  };

  private _setSessionDate = (sessionIdx: number): void => {
    this.sessionIdx = sessionIdx;
    this._saveSettings();
  };

  private _setBook = (bookIdx: number, chapterIdx = -1): void => {
    if (!this.props.signInState) {
      // no enter a book until sign in
      alert(i18n.t('pleaseSignin'));
      return;
    }

    _dataProvider.getBookTable(this.state.books[bookIdx].name).then((bookTable) => {
      // special case when book first created, no free.json etc yet
      if (bookTable.title === '404') {
        bookTable.title = this.state.books[bookIdx].classNameChs;
      }
      const bookContent = undefined; // wipe out the book content value
      this.sessionIdx = 0; // reset to session 0...
      const progress = '';
      this.setState(
        {
          bookIdx,
          chapterIdx,
          bookTable,
          bookContent,
          progress
        },
        () => {
          const bookAccessTime = JSON.parse(localStorage.getItem('classOrder') || '{}');
          bookAccessTime[this.state.books[bookIdx].name] = Date.now();
          localStorage.setItem('classOrder', JSON.stringify(bookAccessTime));

          if (chapterIdx >= 0) {
            this._setChapter(chapterIdx);
          } else {
            this._saveSettings();
          }
        }
      );
    });
  };

  private _setShowContent = () => {
    this.setState({ bookContent: undefined });
  };
  private _setChapter = (chapterIdx: number): void => {
    const bookTable: IBookTable = this.state.bookTable as IBookTable;
    let sessions = bookTable?.sessions;
    if (Array.isArray(bookTable?.content)) {
      sessions = bookTable?.content;
    }
    if (!sessions || sessions?.length <= chapterIdx) {
      console.log('DiscoveryStudy reset chapterIdx to zero: ', chapterIdx);
      chapterIdx = 0; // reset --- there could be a data corruption somewhere, but don't bomb
    }

    const chapterFile = (sessions && sessions[chapterIdx].lesson) || '';
    if (chapterFile === '') {
      console.error('Data error, session has no chapter filename: ', chapterIdx, sessions);
      return;
    }
    const isChapterLessonWebUrl = !chapterFile.toLowerCase().endsWith('.json');
    const progress = '';

    if (isChapterLessonWebUrl) {
      this.setState({ chapterIdx, progress, templateUri: chapterFile }, () => this._saveSettings());
      return;
    }

    _dataProvider
      .getChapterContentAndAnsers(this.state.bookTable?.lessonId || '', chapterFile, chapterIdx)
      .then((bookContent: IChapterContentAndAnswers) => {
        this.sessionIdx = 0; //reset sessionID between chapters
        bookContent.lesson.bookTitle = this.state.bookTable?.title;
        this.setState(
          {
            bookContent,
            chapterIdx,
            progress,
            templateUri: phoneEmulatorTemplate
          },
          () => this._saveSettings()
        );
      });
  };

  private _setBibleCompareState = (merge: boolean) => {
    this.merge = merge;
    this._saveSettings();
  };

  private _setProgress = (progress: string) => {
    const { bookTable } = this.state;
    if (!bookTable) {
      return;
    }
    bookTable!.progress![this.state.chapterIdx] = progress;
    this.setState({ progress, bookTable });
  };

  private _saveSettings() {
    if (!this.state.books || !this.state.books[this.state.bookIdx]) {
      return;
    }

    const settings = {
      name: this.state.books[this.state.bookIdx].name,
      chapterIdx: this.state.chapterIdx,
      sessionIdx: this.sessionIdx,
      merge: this.merge
    };
    this.props.saveSettings(settings);
  }

  public render(): React.ReactNode {
    const dataId = `${this.state.books[this.state.bookIdx]?.classId},${this.state.chapterIdx}`;
    const answerDataId = `answer,${dataId}`;
    //const notesDataId = `notes,${dataId}`;
    const notesDataId = `notes,${this.state.books[this.state.bookIdx]?.classId}`;

    if (!this.state.bookTable) {
      return (
        <>
          <DiscoverBook
            books={this.state.books}
            setBook={this._setBook}
            signInState={this.props.signInState}
            contentHeight={this.state.contentHeight}
          />
        </>
      );
    }

    return (
      <div style={{ height: this.state.contentHeight, display: 'flex' }}>
        {this.state.bibleData && (
          <div className='TableOfContent BibleNavigation'>
            <BibleNavigation
              bibleData={this.state.bibleData}
              // these bible versions need to come out of settings
              // for now default -- TODO
              bibleVersion1Language={i18n.language}
              bibleVersion1Name='和合本'
              bibleVersion2Language='English'
              bibleVersion2Name='American Standard Version'
              merge={this.merge}
              setBible={this._setBible}
              setBibleCompareState={this._setBibleCompareState}
              contentHeight={this.state.contentHeight}
            />
          </div>
        )}
        {!this.state.bibleData && this.state.bookTable && (
          <div className='TableOfContent' style={{ height: this.state.contentHeight }}>
            <TableOfContent
              bookTable={this.state.bookTable}
              chapterIdx={this.state.chapterIdx}
              setChapter={this._setChapter}
              contentHeight={this.state.contentHeight}
              progress={this.state.progress}
            />
          </div>
        )}
        {(this.state.bookContent || this.state.templateUri !== phoneEmulatorTemplate) && (
          <div className='BookContent' style={{ height: this.state.contentHeight }}>
            <PhoneViewer
              bookContent={this.state.bookContent}
              sessionId={this.sessionIdx}
              chapterIdx={this.state.chapterIdx}
              dataId={answerDataId}
              templateUri={this.state.templateUri}
              setSessionDate={this._setSessionDate}
              setBible={this._setBible}
              contentHeight={this.state.contentHeight}
              setProgress={this._setProgress}
              setChapter={this._setChapter}
              setShowContent={this._setShowContent}
            />
          </div>
        )}
        {!_smallScreen && (this.state.bookContent || this.state.templateUri !== phoneEmulatorTemplate) && (
          <Editor dataId={notesDataId} contentHeight={this.state.contentHeight} />
        )}
      </div>
    );
  }
}

export default DiscoverStudy;
