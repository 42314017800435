import React, { useEffect, useState, useRef, useMemo } from 'react';
import { ChangeEvent } from 'react';
import { _dataProvider, IMessage, IUser } from '../dataProvider/DataProvider';
import { getJwtObj, legitHosts } from '../SharedCommon/utils';
import i18n from '../i18n';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { IPromptPickFilesInGroup } from './Dialogues';
import { SearchBox, ISearchBoxStyles } from '@fluentui/react/lib/SearchBox';
import { userAvatarName } from '../SharedCommon/UserAvatarName';
import { UserProfileDialog } from '../SharedCommon/UserProfile';
import './WebChat.css';
import lodash from 'lodash';

import { GiftedChat } from '../gifted/GiftedChat';
import GTSocket from '../dataProvider/socket';
import voiceIcon from '../img/icon-Voice-input.png';
import defaultAvatar from '../img/icon-DefaultProfilePicture.png';
import deleteIcon from '../img/close.png';
import replyIcon from '../img/icon-reply.png';
import searchIcon from '../img/icon-Search-white.png';
import searchActiveIcon from '../img/icon-Search-black.png';
import uploadIcon from '../img/icon-file.png';
import uploadHoverIcon from '../img/icon-FileOpen-black.png';
import sendIcon from '../img/icon-Post.png';
import sendHoverIcon from '../img/icon-Post-black.png';
import AcceptContactCard from './AcceptContactCard';

interface IWebChatProps {
  groupId: number;
  height: string;
  hadNewMessage: boolean;
  launchOneOnOneWithFriend: any;
}

const theAllUserUser = {
  userId: -2,
  name: i18n.t('Chatroom.AllUsers'),
  displayName: i18n.t('Chatroom.AllUsers'),
  loginId: '',
  isGroupLeader: false
};

type FilterType = 'none' | 'file' | 'user' | 'group';

interface IFilterState {
  activeFilter: FilterType;
  filteredMessages: IMessage[];
}

export const WebChat = ({ height, groupId, hadNewMessage, launchOneOnOneWithFriend }: IWebChatProps) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [gtSocket, setGtSocket] = useState<GTSocket>();
  const [fromId, setFromId] = useState(-1);
  const [scrollFromId, setScrollFromId] = useState(-1);
  const [inputText, setInputText] = useState('');
  const [showUserSelection, setShowUserSelection] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [mentionedUsers, setMentionedUsers] = useState<IUser[]>([]);
  const giftedChatRef = useRef<GiftedChat>(null);
  const searchText = useRef('');
  const [searchState, setSearchState] = useState<{ inSearch: boolean; searchedMessages: IMessage[] }>({
    inSearch: false,
    searchedMessages: []
  });
  const [showMessageSender, setShowMessageSender] = useState();
  const [showAcceptCard, setShowAcceptCard] = useState();
  const [filterState, setFilterState] = useState<IFilterState>({
    activeFilter: 'none',
    filteredMessages: []
  });
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [pickFile, setPickFile] = useState(false);

  type MessageFilterFn = (msgs: IMessage[]) => Promise<any> | IMessage[];

  // const buttonElement = document.getElementById('idGroupActionButton');
  // const buttonHeight = buttonElement ? buttonElement.offsetHeight : 0;
  const chatHeight = `${parseInt(height) - 10}px`;

  const mapMessage = (data: any) => {
    return data.map((mmm: any) => {
      const text = mmm.message;
      const pa = text.split('!');

      const giftedm: any = {
        id: mmm.messageId,
        createdAt: new Date(mmm.time * 1000),
        user: {
          id: mmm.user.id,
          name: _dataProvider.getDisplayNameForUser(mmm.user),
          displayName: mmm.user.displayName,
          loginId: mmm.user.loginId,
          avatar: _dataProvider.getAvatarSync(mmm.user.id),
          defaultAvatar //GT added: support when the icon url fails
        },
        quote: mmm.quote //GT added: support reply
      };

      if (text.startsWith('!file!')) {
        const filename = decodeURIComponent(pa[2]);
        const fileSize = parseInt(pa[3]);

        // Display file name and size
        giftedm.text = `${filename} (${(fileSize / 1024).toFixed(2)}KB)`;

        // Add attachment info for file download
        giftedm.attachment = {
          filename: filename,
          fileSize: fileSize,
          url: _dataProvider.getFileUrlInChat(groupId, mmm.messageId)
        };
        giftedm.textStyle = { textDecoration: 'underline' };
        if (getJwtObj().userId === mmm.user.id) {
          giftedm.textStyle.textDecorationColor = 'white';
        }
      } else if (text.startsWith('!image!')) {
        giftedm.image = pa[2];
      } else if (text.startsWith('!audio!')) {
        const duration = parseInt(pa[2]);
        giftedm.text = isNaN(duration) ? 'InvalidAudio' : `${(duration / 1000).toFixed(1)}"`;
        giftedm.icon = {
          image: voiceIcon,
          style: { width: '20px', height: '20px', verticalAlign: 'middle' }
        };
      } else if (text.startsWith('!contact!')) {
        giftedm.card = pa.slice(2);
      } else {
        giftedm.text = text.replace(/\\n/g, '\n');
      }
      return giftedm;
    });
  };

  const getMessageTime = (mmm: any) => {
    if (!mmm || !mmm.createdAt) {
      console.log('Invalid message!', mmm);
      return -1;
    }

    return new Date(mmm.createdAt).getTime();
  };

  const trimFileName = (name: string) => {
    if (name.startsWith('文件 ') || name.startsWith('音频 ') || name.startsWith('图片 ')) {
      return name.slice(3);
    }
    return name;
  };

  const messageFilters: Record<FilterType, MessageFilterFn> = {
    file: async () => {
      const fileList = await _dataProvider.getFileListInGroup(groupId);
      if (!fileList) {
        return [];
      }
      return fileList.map((file) => ({
        id: file.fileId,
        createdAt: new Date(file.time * 1000),
        text: trimFileName(file.displayName),
        user: {
          id: file.user.id,
          name: _dataProvider.getDisplayNameForUser(file.user),
          avatar: _dataProvider.getAvatarSync(file.user.id),
          defaultAvatar //GT added: support when the icon url fails
        },
        attachment: {
          url: _dataProvider.getFileUrlInChat(groupId, file.fileId),
          filename: file.displayName
        },
        textStyle: {
          textDecoration: 'underline',
          textDecorationColor: file.user?.id === getJwtObj().userId ? 'white' : undefined
        }
      }));
    },
    user: async () => {
      const taggedMessages = await _dataProvider.getTaggedMessages(groupId, 'user');
      return taggedMessages ? mapMessage(taggedMessages) : [];
    },
    group: async () => {
      const taggedMessages = await _dataProvider.getTaggedMessages(groupId, 'group');
      return taggedMessages ? mapMessage(taggedMessages) : [];
    },
    none: (msgs: IMessage[]) => msgs
  };

  // Filter application function
  const applyFilter = async (filterType: FilterType) => {
    // Reset search state and hide search box when filter changes
    setShowSearchInput(false);
    searchText.current = '';
    setSearchState({
      inSearch: false,
      searchedMessages: []
    });
    if (filterType === filterState.activeFilter) {
      // When clicking on the active filter, show all messages
      setFilterState({
        activeFilter: 'none',
        filteredMessages: messages
      });
    } else {
      // Apply the selected filter
      const filterFn = messageFilters[filterType];
      const filtered = await filterFn(messages);

      // Ensure we have IMessage[] type for the result
      const filteredMessages = Array.isArray(filtered) ? filtered : mapMessage(filtered);
      setFilterState({
        activeFilter: filterType,
        filteredMessages: filteredMessages
      });
    }
  };

  // Get the filtered messages or all messages if no filter applied
  const getCurrentMessages = () => {
    if (searchState.inSearch) {
      return searchState.searchedMessages;
    }
    if (filterState.activeFilter === 'user' || filterState.activeFilter === 'group') {
      return [...filterState.filteredMessages].reverse();
    }
    if (filterState.activeFilter === 'file') {
      return filterState.filteredMessages;
    }
    return messages;
  };

  /*
    const existingMessages = messages;
    const existingMessageLength = existingMessages.length;

    const timestampFromReceivedMessages = this.getMessageTime(newMessages[0]);
    const timestampFromExistingMessages = existingMessageLength > 0 ? this.getMessageTime(existingMessages[0]) : -1;

    const startTimeFromReceivedMessages = this.getMessageTime(newMessages[newMessages.length - 1]);
    const startTimeFromExistingMessages =
      existingMessageLength > 0 ? this.getMessageTime(existingMessages[existingMessageLength - 1]) : -1;
    if (startTimeFromExistingMessages === -1) {
      this.messageFromTime = startTimeFromReceivedMessages;
    } else {
      this.messageFromTime = Math.min(startTimeFromExistingMessages, startTimeFromReceivedMessages);
    }

    // prepend when received message is older, otherwise append newer message
    const prepend = timestampFromExistingMessages > timestampFromReceivedMessages;
    const combinedMessages = prepend
      ? GiftedChat.prepend(existingMessages, newMessages)
      : GiftedChat.append(existingMessages, newMessages);
  */
  const addMessage = (newmessages: any) => {
    const timestampFromReceivedMessages = getMessageTime(newmessages[0]);
    const timestampFromExistingMessages = getMessageTime(messages[0]);
    const timestamp = Math.max(timestampFromExistingMessages, timestampFromReceivedMessages) / 1000;

    _dataProvider.saveUserData(`chat,${groupId}`, { time: timestamp });

    const newtotal = GiftedChat.append(messages, newmessages);
    setMessages(newtotal);
  };

  const onNewMessage = (data: any) => {
    /* setting a global variable via window.___socket prevented 
      double connection, and double new message!!!
    if (!messages || !messages.length) {
      //mystery case: there are two receives, and one of then had messages empty
      //happens in React.StrictMode -- functions are run twice
      //so two sockets are created!, and each push message is received twice
      //but amazingly: for one of the two, the message.length is zero!
      //  there apears to be two copy of the functional component for states/variables
      //  while the rendering is supported only on one of them.
      //  what's the better way of disambiguate the two
      // this doesn't work for a brand new group === needs a better solution
      return;
    }
    */
    console.log('before receiving messages: ', this, messages, data);
    data.forEach((mmm: any) => {
      // the loginId from the incoming message has the user's email address
      // TODO: when server fix this, this is no longer needed
      const msgUser = users.find((uu) => uu.userId === mmm.user.id || uu.id === mmm.user.id);
      //console.log('newMessage find user: ', msgUser);
      mmm.user.loginId = msgUser?.uniqueId ?? msgUser?.loginId;
    });
    const newmessages = mapMessage(data);
    addMessage(newmessages);
    //console.log('after receiving: ', messages);
  };

  const loadMessages = (from: number, forSearch = false) => {
    let queryString = '';
    if (from !== -1) {
      queryString += `&from=${from}`;
    }
    if (forSearch) {
      queryString += `&search=${encodeURIComponent(searchText.current)}`;
    }
    _dataProvider.getGroupMessage(groupId, queryString.substring(1)).then((groupMessages) => {
      if (!groupMessages.length && from === -1) {
        //when initiating a 1:1 chat, the message length starts at 0
        setMessages([]);
        return;
      }
      if (forSearch) {
        const { inSearch, searchedMessages } = searchState;
        if (from === -1) {
          setSearchState({ inSearch, searchedMessages: mapMessage(groupMessages) });
        } else {
          setSearchState({ inSearch, searchedMessages: [...searchedMessages, ...mapMessage(groupMessages)] });
        }
      } else {
        if (from === -1 && hadNewMessage) {
          //the group has newChatMesage
          _dataProvider.saveUserData(`chat,${groupId}`, { time: groupMessages[0].time });
        }
        if (from === -1) {
          setMessages(mapMessage(groupMessages));
        } else {
          setMessages([...messages, ...mapMessage(groupMessages)]);
        }
      }
    });
  };

  const loadUser = () => {
    _dataProvider.getGroupInfo(groupId).then((groupInfo) => {
      setUsers(groupInfo.users);
    });
  };

  useEffect(() => {
    setFromId(-1);
    setScrollFromId(-1);
    setInputText('');
    setMentionedUsers([]);
    loadMessages(-1);
    loadUser();
    setGtSocket(new GTSocket({ id: groupId, onNewMessage }));
    setFilterState({
      activeFilter: 'none',
      filteredMessages: messages
    });
    setSearchState({ inSearch: false, searchedMessages: [] });
    setShowSearchInput(false);
    searchText.current = '';
    return () => {
      if (gtSocket) {
        gtSocket.closeSocket();
        setGtSocket(undefined);
      }
    };
  }, [groupId]);

  useEffect(() => {
    if (fromId !== -1) {
      // -1 case is done by the groupId change
      // this effect only respond to fromId change from scolling
      loadMessages(fromId);
    }
  }, [fromId]);

  useEffect(() => {
    if (scrollFromId !== -1) {
      // -1 case is done by the innitial search result
      // this effect only respond to fromId change from scolling
      loadMessages(scrollFromId, true);
    }
  }, [scrollFromId]);

  const onDeleteMessage = (message: any) => {
    setMessages([...messages.filter((msg) => msg.id !== message.messageId)]);
  };

  useEffect(() => {
    console.log('state message changed: ', this, messages);
    if (gtSocket) {
      //this has to be done for React.StrictMode so that
      //the receiver will be on the copy of the functional component
      //that has the right state
      //this, when setup, at the constructor time, had
      //no effect on receiving -- somehow, it remember the empty
      //state at the creation time.
      //gtSocket.setCallback(receiveNewMessage);
      gtSocket.newMessageCallback = onNewMessage;
      gtSocket.deleteMessageCallback = onDeleteMessage;
    }
  }, [messages]);

  const sendMessage = (msgtext: string, replyMsgId = -1) => {
    const text = msgtext.trim();
    if (text.length === 0) {
      return; // no op
    }

    const mentioned = [];
    //needs to deal with theAllUserUser ==
    if (text.indexOf(`@${theAllUserUser.name}`) !== -1) {
      users.forEach((user) => mentioned.push(user.userId));
    } else {
      //user might be deleted in the text editing after selection
      for (const user of mentionedUsers) {
        if (text.indexOf(`@${user.name}`) !== -1) {
          mentioned.push(user.userId);
        }
      }
    }
    console.log(mentioned, mentionedUsers);

    _dataProvider.postGroupMessage(groupId, text, replyMsgId, mentioned).then((success) => {
      if (success) {
        //socket connection will receive the new message
        //addMessage(newmessages);
      } else {
        alert('send failed, network might be down...');
      }
    });
  };

  const onSend = (newmessages: any) => {
    console.log('onSend: ', newmessages);
    sendMessage(newmessages[0].text);
  };

  const isNearTop = (layoutHeight: number, contentOffset: number, contentHeight: number) => {
    const topPadding = 80;

    // content height and layout measurement values are float. The comparison can return unexpected result if they have different precision level.
    // Convert them to int to ensure the same precision level to compare.
    const contentH = Math.round(contentHeight);
    const layoutH = Math.round(layoutHeight);

    if (contentH <= layoutH) {
      return false;
    }

    //console.log('Webchat scroll', contentH, layoutH, contentOffset);

    const result = contentH - layoutH - topPadding <= Math.abs(Math.round(contentOffset));
    return result;
  };
  /*
      this.messageFromTime = Number.MAX_VALUE;

  */
  const loadEarlierMessagesAsync = (forSearch = false) => {
    if (messages.length === 0) {
      return;
    }
    if (forSearch) {
      const { searchedMessages } = searchState;
      if (scrollFromId === searchedMessages[searchedMessages.length - 1].id) {
        console.log(`Already called getMessages for ${scrollFromId}, skipping`);
        return;
      }

      //this will trigger an load of early messages
      setScrollFromId(searchedMessages[searchedMessages.length - 1].id);
    } else {
      if (fromId === messages[messages.length - 1].id) {
        console.log(`Already called getMessages for ${fromId}, skipping`);
        return;
      }

      //this will trigger an load of early messages
      setFromId(messages[messages.length - 1].id);
    }
    /*
    const data = { from: fromId, search: searchText };
    if (this.shareInGroups) {
      data.shareInGroups = this.shareInGroups;
    } else {
      data.tag = currentTag;
    }
    await this.chatServer.getMessagesAsync(data);
    */
  };

  const onInputTextChanged = (text: string) => {
    if (searchState.inSearch) {
      //no input?
      return;
    }
    setInputText(text);
    if (text[text.length - 1] === '@') {
      //TODO: this doesn't work if the user type @ in the middle of the text
      // to solve this fully, we need to render our own input<>, and then
      // capture the keydown event == like the mobile app did
      setShowUserSelection(true);
    }
  };

  const handleScroll = (e: any) => {
    if (isNearTop(e.target.clientHeight, e.target.scrollTop, e.target.scrollHeight)) {
      loadEarlierMessagesAsync(searchState.inSearch);
    }
  };

  const renderUserInSelection = (user: IUser) => {
    user.tag = _dataProvider.getMyTagForUser(user.id || user.userId);
    return (
      <div
        key={user.userId}
        className='avatar-wrapper'
        onClick={() => {
          setShowUserSelection(false);
          if (user.userId === -2) {
            //this is theAllUserUser
            //this set is not needed, because onSend will rely on the text
            //setMentionedUsers(users);
          } else if (mentionedUsers.indexOf(user) === -1) {
            setMentionedUsers([user, ...mentionedUsers]);
          }
          setInputText(inputText + user.name + ' ');
          giftedChatRef?.current?.focusTextInput();
        }}>
        {userAvatarName(user, 3, false, '40px', 10)}
      </div>
    );
  };

  const deleteMsg = async (msg: any) => {
    const userConfirmed = confirm(i18n.t('Chatroom.ConfirmDelete'));
    if (userConfirmed) {
      const success = await _dataProvider.deleteChatMessage(groupId, msg.id);
      if (success) {
        // need to refresh, but there is delete notification coming??
      }
    }
  };

  const replyMsg = (msg: any) => {
    const replyText = prompt(i18n.t('Chatroom.ReplyPrompt'));
    if (replyText) {
      sendMessage(replyText, msg.id);
    }
  };

  const messageActionSheet = (msg: any) => {
    const actions = [{ label: i18n.t('Chatroom.Reply'), icon: replyIcon, action: replyMsg }];

    let allowDelete = getJwtObj().userId === msg.user.id;
    if (!allowDelete) {
      const uuu = users.find((uu) => getJwtObj().userId === uu.userId);
      allowDelete = !!uuu && uuu.isGroupLeader;
    }

    if (allowDelete) {
      actions.push({ label: i18n.t('authoring.Delete'), icon: deleteIcon, action: deleteMsg });
    }
    return actions;
  };

  const onPress = (msg: any) => {
    if (msg.attachment) {
      const url = msg.attachment.url;
      if (!url) {
        return;
      }
      console.log('!!!!!!!!!!!url is: ', url);
      if (msg.attachment.filename.indexOf('.docx') === msg.attachment.filename.length - 5) {
        const remoteFile = _dataProvider.getFileUrlInChat(groupId, msg.id);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = remoteFile;
        document.body.appendChild(iframe);
      } else {
        window.open(url);
      }
    } else if (msg.card) {
      setShowAcceptCard(msg.card);
    }
  };

  const onUrlPress = async (url: string) => {
    try {
      const urlObj = new URL(url);

      //take the last two part of the hostname
      const rootDomain = urlObj.hostname.split('.').slice(-2).join('.');
      if (legitHosts.includes(rootDomain)) {
        if (!urlObj.pathname.startsWith('/launch')) {
          window.open(url);
          return;
        }
        const params = urlObj.searchParams;
        const code = params.get('code') || '';
        const result = await _dataProvider.launchUrl(code);
        if (!result) {
          return;
        }
        if (result?.cmd === 'joinStudyGroup') {
          console.log('join: ', groupId, result, result.data);
          if (groupId.toString() !== result?.data?.value) {
            const msg = result?.data?.status ? 'Chatroom.JoinSuccess' : 'Chatroom.JoinWaiting';
            alert(i18n.t(msg));

            //reload the page that will show the new group
            window.location.reload();
          }
        }
      } else {
        window.open(url);
      }
    } catch (error) {
      alert(error);
    }
  };

  const toggleSearch = () => {
    setShowSearchInput(!showSearchInput);
    if (!showSearchInput) {
      // Activate search state and cancel filters
      clearSearchState();
      setFilterState({
        activeFilter: 'none',
        filteredMessages: messages // Reset to all messages
      });
    } else {
      // Clear search state when search box is hidden
      clearSearchState();
    }
  };

  // Delay search execution until user stops typing for 500ms to prevent frequent API calls
  const debouncedSearch = useMemo(
    () =>
      lodash.debounce((value: string) => {
        _dataProvider.getGroupMessage(groupId, `search=${encodeURIComponent(value)}`).then((groupMessages) => {
          setScrollFromId(-1);
          setSearchState({ inSearch: true, searchedMessages: mapMessage(groupMessages) });
        });
      }, 500), // 500ms delay
    [groupId]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const handleSearchChange = (e?: ChangeEvent<HTMLInputElement>, value?: string) => {
    if (!value) {
      clearSearchState();
      return;
    }
    debouncedSearch(value);
  };

  const clearSearchState = () => {
    searchText.current = '';
    setSearchState({ inSearch: false, searchedMessages: [] });
  };

  const pickFile2Download = async (groupId: number, fileId: number): Promise<void> => {
    console.log('----------fileid is: ', fileId);
    const remoteFile = _dataProvider.getFileUrlInChat(groupId, fileId);
    console.log('download: ', remoteFile);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = remoteFile;
    document.body.appendChild(iframe);

    //most of the time, picking something also dismiss the dialogue
    //but in this scenario, the user tends to download multiple files...
    //so keep the dialogue visible, and change the title somewhat, from
    //pick, to click to download the files you desire.
    //setPickFile(false);
  };

  const uploadAction = () => {
    const inputF = document.createElement('input');
    inputF.setAttribute('type', 'file');
    inputF.setAttribute('accept', '*.*');
    inputF.setAttribute('multiple', 'multiple');

    inputF.onchange = async (e) => {
      const tg: any = e?.target;
      const fileList = Array.from(tg?.files);
      if (!fileList || fileList.length === 0) {
        return;
      }
      let success = true;
      for (const file of fileList) {
        const result = await _dataProvider.uploadFileWithChatMessage(file, groupId);
        if (!result) {
          success = false;
        }
      }

      if (success) {
        alert(i18n.t('GroupScreen.UploadSuccess'));
      } else {
        alert('Network connection failed, try again');
      }
    };
    inputF.click();
  };

  const renderActionIcon = () => {
    return (
      <img
        src={uploadIcon}
        title={i18n.t('uploadDocument')}
        style={{
          width: '26px',
          height: '26px'
        }}
      />
    );
  };

  const renderActionHoverIcon = () => (
    <img
      src={uploadHoverIcon}
      title={i18n.t('uploadDocument')}
      style={{
        width: '26px',
        height: '26px'
      }}
    />
  );

  const renderSendIcon = () => (
    <img
      src={sendIcon}
      alt='Send'
      style={{
        width: '30px',
        height: '30px'
      }}
    />
  );

  const renderSendHoverIcon = () => (
    <img
      src={sendHoverIcon}
      alt='Send'
      style={{
        width: '30px',
        height: '30px'
      }}
    />
  );

  const onPressAvatar = (user: any) => {
    user.tag = _dataProvider.getMyTagForUser(user.id);
    user.block = _dataProvider.getMyBlockForUser(user.id);
    console.log('Avatar: ', user);
    setShowMessageSender(user);
  };

  const renderCustomView = (msg: any) => {
    if (msg.card) {
      const contactDisplayName = msg.card[1] || '';

      return (
        <div style={{ padding: 14, paddingBottom: 0 }}>
          <div style={{ flexDirection: 'row', padding: 0 }}>
            <img src={defaultAvatar} alt='' style={{ verticalAlign: 'middle', width: 60, height: 60 }} />
            <span style={{ paddingLeft: 10 }}>{contactDisplayName}</span>
          </div>
          <p style={{ width: 64, fontSize: 12, textAlign: 'center', color: '#707070' }}>
            {i18n.t('Chatroom.ContactCard')}
          </p>
        </div>
      );
    }
  };

  const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
      width: '100%',
      backgroundColor: 'white',
      borderRadius: '4px',
      padding: '4px',
      border: '1px solid #a0a0a0'
    },
    icon: {
      color: '#666666'
    }
  };

  return (
    <div className='webchat-container' style={{ height: chatHeight }}>
      <div id='idGroupActionButton' className='fellowshipActionDiv'>
        <button
          className={`fellowshipAction ${filterState.activeFilter === 'user' ? 'activeButton' : ''}`}
          onClick={() => applyFilter('user')}>
          {i18n.t('Chatroom.MyFavorite')}
        </button>
        <button
          className={`fellowshipAction ${filterState.activeFilter === 'group' ? 'activeButton' : ''}`}
          onClick={() => applyFilter('group')}>
          {i18n.t('Chatroom.GroupFavorite')}
        </button>
        <button
          className={`fellowshipAction ${filterState.activeFilter === 'file' ? 'activeButton' : ''}`}
          onClick={() => applyFilter('file')}>
          {i18n.t('document')}
        </button>
        <button className='fellowshipAction' onClick={() => setPickFile(true)}>
          {i18n.t('downloadDocument')}
        </button>
        <button className={`search-button ${showSearchInput ? 'active' : ''}`} onClick={toggleSearch}>
          <img src={searchIcon} alt='Search' className='search-icon-default' />
          <img src={searchActiveIcon} alt='Search' className='search-icon-active' />
        </button>
      </div>
      {showSearchInput && (
        <div className='search-input-container'>
          <SearchBox
            styles={searchBoxStyles}
            placeholder={i18n.t('Search')}
            onChange={handleSearchChange}
            onClear={clearSearchState}
          />
        </div>
      )}
      <div className='giftedchat-container'>
        <GiftedChat
          ref={giftedChatRef}
          text={inputText}
          messages={getCurrentMessages()}
          onSend={onSend}
          user={{
            id: getJwtObj().userId
          }}
          renderUsernameOnMessage={true}
          renderCustomView={renderCustomView}
          placeholder={i18n.t('Chatroom.inputPlaceHolder')}
          listViewProps={{
            onScroll: handleScroll
          }}
          onInputTextChanged={onInputTextChanged}
          onQuotePress={(msg: any) => alert(msg.quote)}
          messageActionSheet={messageActionSheet}
          onUrlPress={onUrlPress}
          onPress={onPress}
          onPressAvatar={onPressAvatar}
          onPressActionButton={uploadAction}
          renderActionIcon={renderActionIcon}
          renderActionLabel={i18n.t('uploadDocument')}
          renderActionHoverIcon={renderActionHoverIcon}
          renderSendIcon={renderSendIcon}
          renderSendHoverIcon={renderSendHoverIcon}
          alwaysShowSend={true}
          label={<div style={{ whiteSpace: 'nowrap' }}>{i18n.t('Chatroom.send')}</div>}
        />
      </div>
      {pickFile && (
        <IPromptPickFilesInGroup
          groupId={groupId}
          processFile={(groupId: number, fileId: number) => pickFile2Download(groupId, fileId)}
          onDismiss={() => setPickFile(false)}
        />
      )}
      {showUserSelection && (
        <Dialog
          hidden={false}
          onDismiss={() => setShowUserSelection(false)}
          dialogContentProps={{
            showCloseButton: true
          }}>
          <div className='member-container'>
            {renderUserInSelection(theAllUserUser)}
            {users.map(renderUserInSelection)}
          </div>
        </Dialog>
      )}
      {showMessageSender && (
        <UserProfileDialog
          user={showMessageSender}
          dismiss={setShowMessageSender}
          launchOneOnOneWithFriend={launchOneOnOneWithFriend}
        />
      )}
      {showAcceptCard && (
        <AcceptContactCard
          userCard={showAcceptCard}
          dismiss={setShowAcceptCard}
          launchOneOnOneWithFriend={launchOneOnOneWithFriend}
        />
      )}
    </div>
  );
};
