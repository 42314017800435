import React, { useEffect, useState } from 'react';
import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import { userAvatarName } from './UserAvatarName';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { DefaultButton, IButtonStyles } from '@fluentui/react';
import i18n from '../i18n';

export interface IUserProfileDialogProps {
  user: IUser | undefined;
  dismiss: any;
  launchOneOnOneWithFriend: any;
}

export interface IUserProfileProps {
  user: IUser | undefined;
  updateFrameStyle?: any;
  launchOneOnOneWithFriend?: any;
  onDismiss?: () => void;
}

export const UserProfile: React.FunctionComponent<IUserProfileProps> = ({
  user,
  updateFrameStyle,
  launchOneOnOneWithFriend,
  onDismiss
}) => {
  if (!user) {
    return null; // Render nothing if user is undefined
  }
  const [block, setBlock] = useState(user.block);
  const [tag, setTag] = useState(_dataProvider.getMyTagForUser(user.id || user.userId));
  const shareEnabled = false;

  const updateTag = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTag(ev.target.value);
  };

  useEffect(() => {
    if (block !== user.block || tag !== _dataProvider.getMyTagForUser(user.id || user.userId)) {
      handleTagAndBlockUpdate();
    }
  }, [block, tag]);

  // Silent update
  const handleTagAndBlockUpdate = async () => {
    await _dataProvider.updateContactForUser(user.id || user.userId, tag, Number(!!block));
  };

  const handleCommunicate = () => {
    if (onDismiss) {
      onDismiss();
    }
    if (launchOneOnOneWithFriend) {
      launchOneOnOneWithFriend(user.userId || user.id, '', user.uniqueId || user.loginId);
    }
  };

  const handleShare = () => {
    // Share functionality to be implemented
    console.log('Share functionality not implemented yet');
  };

  const buttonStyles: Partial<IButtonStyles> = {
    root: {
      width: '200px !important',
      height: '36px !important',
      border: '1px solid #BBBBBB !important',
      borderRadius: '18px !important',
      padding: '0px !important',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1) !important'
    }
  };

  return (
    <div>
      <div className='selected-user-container'>
        <br />
        {userAvatarName(user, 2, true)}
      </div>
      <div style={updateFrameStyle}>
        <div className='avatar-name'>
          <h3>{i18n.t('MemberScreen.UserTag')}</h3>
          <input
            key='titleText'
            placeholder={tag ? tag : i18n.t('MemberScreen.NoTag')}
            value={tag}
            onChange={updateTag}
          />
        </div>
        <div className='avatar-name'>
          <h3>
            <input type={'checkbox'} checked={block} onChange={() => setBlock(!block)} />
            <span>{i18n.t('MemberScreen.BlockUser')}</span>
          </h3>
          <div className='checkbox-content'>{i18n.t('MemberScreen.BlockUserText')}</div>
        </div>
        <br></br>
        <div
          className='button-container'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px'
          }}>
          {launchOneOnOneWithFriend && (
            <DefaultButton styles={buttonStyles} onClick={handleCommunicate}>
              {i18n.t('Communicate')}
            </DefaultButton>
          )}
          {shareEnabled && (
            <DefaultButton styles={buttonStyles} onClick={handleShare}>
              {i18n.t('Share')}
            </DefaultButton>
          )}
        </div>
      </div>
    </div>
  );
};

export const UserProfileDialog: React.FunctionComponent<IUserProfileDialogProps> = ({
  user,
  dismiss,
  launchOneOnOneWithFriend
}) => {
  return (
    <Dialog
      hidden={false}
      onDismiss={() => {
        dismiss(undefined);
      }}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { borderRadius: '20px' } }
      }}>
      <UserProfile
        user={user}
        onDismiss={() => {
          dismiss(undefined);
        }}
        launchOneOnOneWithFriend={launchOneOnOneWithFriend}
      />
    </Dialog>
  );
};
