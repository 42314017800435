import axios from 'axios';
import { wipeSignInState, getToken, cryptoProxy, useCryptoProxy } from '../SharedCommon/utils';
import cryptoUtils from './cryptoproxy/cryptoUtil';
import { cryptoRequest } from './cryptoproxy/cryptoAxios';

function createAxiosInstance(baseURL: string, getLocale: () => string) {
  console.log('useCryptoProxy:', useCryptoProxy, cryptoProxy);

  const axiosInstance = axios.create({
    baseURL
  });

  const savedState = localStorage.getItem('sessionState');
  cryptoUtils.init(savedState);

  // set up request header configurations
  axiosInstance.interceptors.request.use(
    async (config) => {
      config.headers = {
        lang: getLocale(), // use callback here to get the most updated language option
        'cache-control': 'no-cache'
      };

      if (!useCryptoProxy) {
        const authToken = getToken();
        if (authToken) {
          config.headers['Authorization'] = 'Bearer ' + authToken;
        }

        return config;
      }

      return cryptoRequest(config, cryptoProxy);
    },
    (error) => {
      console.log('customAxios request Error: ', error, error?.response?.status);
      return Promise.reject(error);
    }
  );

  // handle error msg 401 on response
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log('customAxios response Error: ', error, error?.response?.status);
      if (error.response?.status === 401) {
        wipeSignInState();
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

export default createAxiosInstance;
