import { IAppSessionSettings } from '../dataProvider/IAppSessionSettings';
import Footer from '../components/Footer';

import { _dataProvider } from '../dataProvider/DataProvider';
import { DefaultButton } from '@fluentui/react';
import React from 'react';

import { SERVER_URL, isNotDev } from '../SharedCommon/utils';
import i18n from '../i18n';

import { getOrgLogo } from '../SharedCommon/OrgList';

interface IAppDownloadProps {
  saveSettings: any;
  signInState: boolean;
  settings: IAppSessionSettings;
  HeadClickCount: number;
}

class AppDownload extends React.Component<IAppDownloadProps> {
  private sessionIdx: number;
  private merge: boolean;

  constructor(props: IAppDownloadProps) {
    super(props);
    this.merge = true;
    this.sessionIdx = 0;

    this.state = {};
    console.log('navigator.appVersion: ', window.navigator.appVersion);
  }

  public componentDidMount() {
    console.log('Mounted');
  }

  render(): React.ReactNode {
    const IDigestLogo = getOrgLogo(_dataProvider.getOrgId());
    const downloadUrl = isNotDev
      ? window.location.protocol + '//' + window.location.hostname + '/install'
      : SERVER_URL + '/install';
    return (
      <>
        <div
          style={{
            display: 'block',
            margin: 'auto',
            width: '50%',
            marginTop: '100px'
          }}>
          <img src={IDigestLogo} alt='IDigest Logo' style={{ display: 'block', height: '48px', margin: 'auto' }}></img>
          <h3 style={{ margin: 'auto', textAlign: 'center', marginTop: '20px' }}>
            {i18n.t('LinkToInstall')}
            <p>{downloadUrl}</p>
            <a href={downloadUrl} style={{ marginRight: 0 }}>
              <DefaultButton
                styles={{
                  root: {
                    marginTop: '20px',
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: '25px'
                  }
                }}>
                {i18n.t('DownloadInstall')}
              </DefaultButton>
            </a>
            <br></br>
            <br></br>
            <br></br>
            {window.navigator.userAgent.indexOf('Mac') === -1 && i18n.t('PWAInstallNote')}
          </h3>
        </div>
        <Footer />
      </>
    );
  }
}

export default AppDownload;
