import React from 'react';
import { DefaultButton, Dropdown, IDropdownOption, ContextualMenu } from '@fluentui/react';
import { getJwtObj, _smallScreen, wipeSignInState } from './utils';
import { FallbackNs, Translation } from 'react-i18next';
import i18n from '../i18n';
import { NavigateFunction } from 'react-router-dom';
import SignUp from '../components/SignUp';
import { TFunction } from 'i18next';
import PasswordReset from '../components/PasswordReset';
import { Dialog } from '@fluentui/react/lib/Dialog';
import '../components/Dialog.css';
import NewPassword from '../components/NewPassword';
import { _dataProvider } from '../dataProvider/DataProvider';

enum Window {
  Login = 'LOGIN',
  Signup = 'SIGNUP',
  ResetPassword = 'RESET_PASSWORD',
  NewPassword = 'NEW_PASSWORD'
}

interface ISignInState {
  isOpen: boolean;
  window: Window;
  error?: string;
  displayName: string;
  isMenuOpen: boolean;
  target: HTMLElement | null;
  loginData: any;
}

interface ISignInProps {
  signInOut: any; // (any, boolean) :void ;  <-1 == success, -2== signout>>
  loginUrl: string;
  pwa: boolean;
  signInState: boolean;
  promptSignIn: number;
  navigate: NavigateFunction;
}

//res.json({ accessToken, reset, displayName, isChurchAdmin, churchName, organizations });
//app.post('/user/login', login);

class SignIn extends React.Component<ISignInProps, ISignInState> {
  constructor(props: ISignInProps) {
    super(props);

    const jwtObj = getJwtObj();
    const topen = !props.signInState && (props.pwa || props.promptSignIn !== 0);

    this.state = {
      isOpen: topen,
      window: props.promptSignIn === 2 ? Window.Signup : Window.Login,
      displayName: jwtObj.displayName || '',
      isMenuOpen: false,
      target: null,
      loginData: { loginId: '', password: '' }
    };

    console.log('signin init:', this.state.displayName, jwtObj);
  }

  shouldComponentUpdate(nextProps: Readonly<ISignInProps>): boolean {
    if (this.props.promptSignIn !== nextProps.promptSignIn) {
      this.setState({
        isOpen: !nextProps.signInState && nextProps.promptSignIn !== 0,
        window: nextProps.promptSignIn === 2 ? Window.Signup : Window.Login
      });
    }
    return true;
  }

  handleToggleSignUp = () => {
    this.setState({ window: Window.Signup });
  };

  handleSignedIn = () => {
    const error = undefined;
    const jwtObj = getJwtObj();
    console.log('signin returned and the state: ', jwtObj);
    this.setState({ error, displayName: jwtObj.displayName || '' });
    this.props.signInOut(true);
  };

  handleNewPass = () => {
    this.setState({ window: Window.NewPassword });
  };

  handleSignIn = async () => {
    const response = await _dataProvider.signIn(this.state.loginData.loginId, this.state.loginData.password);

    if (response.success) {
      console.log('signin returned: ', response.data);
      if (response.data.reset == true) {
        this.handleNewPass();
      } else {
        this.handleSignedIn();
      }
    } else {
      const error = response.error;
      this.setState({ error });
      alert(error);
    }

    //wipe out password after sign in, no matter success or failure
    const ldata = { loginId: this.state.loginData.loginId, password: '' };

    //wipe out pwd from memory
    this.setState({ loginData: ldata });
  };

  handleSignOut = () => {
    const error = undefined;

    wipeSignInState();
    this.setState({ error, isOpen: false, displayName: '' });
    this.props.signInOut(false);
    this.props.navigate('/');
  };

  handleLoginIdInput = (event: any) => {
    const { password } = this.state.loginData;
    const loginId = event.target.value;
    this.setState({ loginData: { loginId, password } });
    return true;
  };

  handlePasswordInput = (event: any) => {
    const { loginId } = this.state.loginData;
    const password = event.target.value;
    this.setState({ loginData: { loginId, password } });
    return true;
  };

  fetchDisplayName = async () => {
    const { displayName } = await _dataProvider.getMyProfile();
    this.setState({ displayName });
  };

  handleDisplayNameChange = () => {
    this.fetchDisplayName();
  };

  handleLanguageChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      i18n.changeLanguage(option.key as string);
    }
  };

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      isMenuOpen: true,
      target: event.currentTarget
    });
  };

  handleMenuDismiss = () => {
    this.setState({ isMenuOpen: false });
  };

  renderContent = (t: any) => {
    switch (this.state.window) {
      case Window.Login:
        return this.showLoginScreen(t);
      case Window.Signup:
        return (
          <SignUp
            close={this.togglePopup}
            back2Signin={this.back2Signin}
            signInOut={(isSignedIn) => {
              this.props.signInOut(isSignedIn);
              if (isSignedIn) {
                this.handleSignedIn();
              }
            }}
          />
        );
      case Window.ResetPassword:
        return <PasswordReset close={this.togglePopup} />;
      case Window.NewPassword:
        return <NewPassword close={this.handleSignedIn} />;
      default:
        return null;
    }
  };

  PopupScreen = (props: { handleClose: (ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any }) => {
    return (
      <Translation>
        {(t) => (
          <Dialog
            hidden={false}
            onDismiss={props.handleClose}
            dialogContentProps={{
              showCloseButton: true
            }}
            modalProps={{
              isBlocking: false,
              styles: {
                main: {
                  padding: _smallScreen ? '4px' : '20px',
                  width: '75vh !important', // Ensures the dialog is square, responsive to viewport dimensions
                  minheight: '75vh', // Ensures the dialog is square, responsive to viewport dimensions
                  borderRadius: '25px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  maxWidth: 'none !important',
                  maxHeight: 'none !important'
                }
              }
            }}>
            <div style={{ width: '100%' }}>{this.renderContent(t)}</div>
          </Dialog>
        )}
      </Translation>
    );
  };

  private showLoginScreen(t: TFunction<FallbackNs<undefined>, undefined>) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ textAlign: 'center', width: '300px' }}>
          <h2 className={'dialog-header'}>{t('login')}</h2>
          <h5 style={{ marginTop: '15px', marginBottom: '15px' }}>{t('Email')}</h5>
          <input
            placeholder={t('example')}
            id='loginId'
            onChange={this.handleLoginIdInput}
            value={this.state.loginData.loginId ?? ''}
            className={'dialog-box'}
          />
          <h5 className={'dialog-label'}>{t('pwd')}</h5>
          <input type='password' onChange={this.handlePasswordInput} className={'dialog-box'} />
          <h5
            style={{
              textDecoration: 'underline',
              marginBottom: '15px'
            }}
            onClick={() => this.setState({ window: Window.ResetPassword })}>
            {t('ForgotPassword')}
          </h5>
          <div style={{ display: 'table', margin: '0 auto' }}>
            <DefaultButton
              onClick={this.handleSignIn}
              styles={{
                root: {
                  backgroundColor: 'black',
                  color: 'white',
                  borderRadius: '25px',
                  display: 'table-cell',
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '10px'
                }
              }}>
              {t('login')}
            </DefaultButton>
            <DefaultButton
              onClick={this.handleToggleSignUp}
              styles={{
                root: {
                  backgroundColor: 'black',
                  color: 'white',
                  borderRadius: '25px',
                  display: 'table-cell',
                  width: '100%',
                  textAlign: 'center'
                }
              }}>
              {t('CreateNewAccount')}
            </DefaultButton>
            <h5 className={'dialog-label'}>{'Change language/更改语言'}</h5>
            <Dropdown
              className='dialog-dropdown'
              selectedKey={i18n.language ?? 'chs'}
              onChange={this.handleLanguageChange}
              options={[
                { key: 'eng', text: 'English' },
                { key: 'chs', text: '简体中文' },
                { key: 'cht', text: '繁體中文' }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }

  private togglePopup = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.setState({ window: Window.Login });
  };

  private back2Signin = () => {
    this.setState({ window: Window.Login });
  };

  private showMySettings = () => {
    this.props.navigate('/my-settings');
  };

  componentDidUpdate(prevProps: ISignInProps) {
    // 只有当 signInState 发生变化时才记录
    if (prevProps.signInState !== this.props.signInState) {
      console.log('SignInHeader componentDidUpdate, signInState:', this.props.signInState);
    }
  }

  componentDidMount(): void {
    this.fetchDisplayName();
    _dataProvider.addDisplayNameChangeListener(this.handleDisplayNameChange);
  }

  componentWillUnmount(): void {
    _dataProvider.removeDisplayNameChangeListener(this.handleDisplayNameChange);
  }

  render() {
    const { isMenuOpen, target } = this.state;
    console.log('signin render:', this.state.displayName, this.props.signInState);
    return (
      <>
        {!this.props.signInState && (
          <Translation>
            {(t) => (
              <div
                style={{
                  border: 'none',
                  float: this.props.pwa ? 'left' : 'right'
                }}>
                <button
                  onClick={this.togglePopup}
                  style={{
                    fontSize: '16px',
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                    padding: 0,
                    border: 'none',
                    background: 'none',
                    whiteSpace: 'nowrap'
                  }}
                  className={'headerButton'}>
                  {t('login')}
                </button>
                /
                <button
                  onClick={() => {
                    this.togglePopup();
                    this.setState({ window: Window.Signup });
                  }}
                  style={{
                    fontSize: '16px',
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                    padding: 0,
                    border: 'none',
                    background: 'none',
                    whiteSpace: 'nowrap'
                  }}
                  className={'headerButton'}>
                  {t('signup')}
                </button>
                {this.state.isOpen && <this.PopupScreen handleClose={this.togglePopup} />}
              </div>
            )}
          </Translation>
        )}
        {this.props.signInState && (
          <Translation>
            {(t) => (
              <div
                style={{
                  border: 'none',
                  float: this.props.pwa ? 'left' : 'right',
                  lineHeight: 'initial'
                }}>
                <DefaultButton
                  className='circle'
                  onClick={this.handleMenuOpen}
                  styles={{
                    root: {
                      borderRadius: '50%',
                      padding: 0,
                      minWidth: 'none',
                      borderWidth: '1px'
                    }
                  }}>
                  {this.state.displayName ? this.state.displayName.slice(0, 6) : t('Exit')}
                </DefaultButton>
                {isMenuOpen && (
                  <ContextualMenu
                    target={target}
                    items={[
                      {
                        key: i18n.t('MyProfile.MySettings'),
                        text: i18n.t('MyProfile.MySettings'),
                        onClick: () => {
                          this.showMySettings();
                          this.handleMenuDismiss();
                        }
                      },
                      {
                        key: i18n.t('Exit'),
                        text: i18n.t('Exit'),
                        onClick: () => {
                          this.handleSignOut();
                          this.handleMenuDismiss();
                        }
                      }
                    ]}
                    onDismiss={this.handleMenuDismiss}
                  />
                )}
              </div>
            )}
          </Translation>
        )}
      </>
    );
  }
}

export default SignIn;
