// this file is to be maintained to be the same as in webidigest
// so that we can maintain the crypto protocol consistency -- test
// only once and work in both. Nearly 100% of this code is used in both
// but there are small variance between web and app

import forge from 'node-forge';
import { saveCryptoState } from '../cryptoInit';

const pubPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgSyufrPFJnzntAsaFhyC
TE1oEizEAHZlD7UKti+8srTL4WDoH7HSHSD1z0alnf9idUgi+5NQk0JqBp9DMFad
4wBpEslRw3USbzkEHGRMk6EXCFZeFXqUeUEpVxLMpj/DrkcP8DTs4OoN6yOYnBr5
BHMFRWxpWJXedZ8ioGdenRs13eSuZWxUPup42kFXfiEgM+o1gZQs6L7oJmOnWW7G
GspPON5ss6KOcAsNZtURFzwA5FLHIuHQ3cJgrUZOA9WwPtyXWhyCekzDMfL7Cuo9
1hImwRBC8jPHE3vxAsV3hKCrfu39Koqn1DpV7uC+GzBuXeb99q2tX/azDLIX4qmw
kQIDAQAB
-----END PUBLIC KEY-----`;

class CryptoUtils {
  constructor() {
    this.pubkey = forge.pki.publicKeyFromPem(pubPem);
    this.reset();
  }

  init(savedState) {
    let sessionState = savedState;
    if (typeof sessionState !== 'object') {
      sessionState = JSON.parse(savedState || '{}');
    }
    this.sessionKey = forge.util.decode64(sessionState?.sessionKey ?? '');
    this.secureToken = sessionState?.secureToken;
    this.accessToken = sessionState?.accessToken;
  }

  reset() {
    this.sessionKey = '';
    this.secureToken = '';
    this.accessToken = '';
  }

  encryptPublic(data) {
    const encrypted = forge.util.encode64(this.pubkey.encrypt(data, 'RSA-OAEP'));
    return encrypted;
  }

  encryptPublicWithAccessToken() {
    if (!this.accessToken) {
      throw new Error('no access token');
    }

    const md = forge.md.sha256.create();
    md.update(this.accessToken);
    const key = md.digest().bytes();

    const encrypted = forge.util.encode64(this.pubkey.encrypt(key, 'RSA-OAEP'));
    return { encrypted, key };
  }

  decrypt(key, msg, utf8 = false) {
    const encrypted = forge.util.decode64(msg);
    const iv = encrypted.slice(0, 16);
    const content = encrypted.slice(16);

    const decipher = forge.cipher.createDecipher('AES-CBC', key);
    decipher.start({ iv: iv });
    const encryptedString = forge.util.createBuffer(content);
    decipher.update(encryptedString);
    decipher.finish();
    const data = utf8 ? decipher.output.toString() : decipher.output.bytes();
    //const data = decipher.output.bytes();
    return data;
  }

  verify(message, signature, pubkey) {
    const sign = forge.util.decode64(signature);
    //const sign = signature;
    const msg = forge.util.decode64(message);

    const md = forge.md.sha256.create();
    md.update(msg);
    const verified = pubkey.verify(md.digest().bytes(), sign);

    return verified;
  }

  encryptSession(msg) {
    if (!this.sessionKey) {
      return undefined;
    }
    return this.encryptSymmetric(this.sessionKey, msg);
  }

  encryptSymmetric(key, msg) {
    if (typeof msg === 'object') {
      msg = JSON.stringify(msg);
    }
    const iv = forge.random.getBytesSync(16);
    const cipher = forge.cipher.createCipher('AES-CBC', key);
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(msg, 'utf8'));
    cipher.finish();
    const encrypted = [iv, cipher.output.bytes()].join('');
    return forge.util.encode64(encrypted);
  }

  /* no longer needed
  decodeBinaryString(data) {
    const buffer = new ArrayBuffer(data.length);
    const uint8View = new Uint8Array(buffer);
    for (let iii = data.length - 1; iii >= 0; iii--) {
      uint8View[iii] = data.charCodeAt(iii);
    }

    const decoder = new TextDecoder('utf-8');
    const decodedString = decoder.decode(uint8View);

    return decodedString;
  }
  */

  decryptSession(msg, utf8 = true) {
    const data = this.decrypt(this.sessionKey, msg, utf8);
    return data;
    //return this.decodeBinaryString(data);
  }

  decryptPwd(loginId, pwd, data) {
    if (!data) {
      return { error: 'no data' };
    }
    const md = forge.md.sha256.create();
    md.update(pubPem);
    md.update(loginId);
    md.update(pwd);
    const key = md.digest().bytes();

    return this.decryptPubkeyResponse(key, data);
  }

  decryptPubkeyResponse(key, data) {
    const msg = data.split('.')[0];
    const signature = data.split('.')[1];
    if (!signature) {
      return { error: 'no signature' };
    }

    const validated = this.verify(msg, signature, this.pubkey);
    if (!validated) {
      return { error: 'signature failed' };
    }
    const decryptedMsg = this.decrypt(key, msg);
    this.sessionKey = decryptedMsg.slice(0, 32);

    //get the length of the security token
    const tokenLenBuf = decryptedMsg.slice(32, 34);
    const forgeBuf = forge.util.createBuffer();
    forgeBuf.putBytes(tokenLenBuf);
    const hexString = forgeBuf.toHex();
    const int16 = Number('0x' + hexString);

    this.secureToken = forge.util.encode64(decryptedMsg.slice(34, int16 + 34));

    let moreData = decryptedMsg.slice(34 + int16);
    moreData = forge.util.createBuffer(moreData);
    moreData = moreData.toString();
    //the above two lines works, as for the following line, but less our own code
    //moreData = this.decodeBinaryString(moreData);

    const moreDataObj = JSON.parse(moreData);

    this.accessToken = moreDataObj.accessToken;

    const saveKey = forge.util.encode64(this.sessionKey);
    const sessionState = {
      sessionKey: saveKey,
      secureToken: this.secureToken,
      accessToken: this.accessToken
    };

    saveCryptoState(sessionState);

    return moreData;
  }

  getSessionSecurityToken() {
    return this.secureToken;
  }

  getAccessToken() {
    return this.accessToken;
  }
}

const cryptoUtils = new CryptoUtils();

export default cryptoUtils;
