import React from 'react';
//import { Text, DefaultButton } from '@fluentui/react';
//import { _dataProvider } from '../dataProvider/DataProvider';
import i18n from '../i18n';

interface INotify {
  count: number;
}

const Notify = (props: INotify) => {
  if (props.count > 0 && !window.location.href.includes('/#/group')) {
    const msg = i18n.t('Notify');
    const notify = msg.replace('NNN', props.count.toString());
    return (
      <div
        style={{
          position: 'absolute',
          right: 50,
          top: 60,
          border: 'solid 1px',
          background: 'yellow',
          padding: '15px',
          zIndex: 10
        }}>
        {notify}
      </div>
    );
  } else {
    return <></>;
  }
};

export default Notify;
