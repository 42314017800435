//import React, { useEffect, useState } from 'react';
import React from 'react';
import { _dataProvider } from '../dataProvider/DataProvider';

const BookCoverImage = (props) => {
  const { bookId, bookName, className, style } = props;
  const imageUrl = _dataProvider.getBookCover(bookId);

  /*
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    _dataProvider.getBookCover(bookId).then((url) => setImageUrl(url));
  }, []);
  */

  return (
    <>
      {bookName && <h2 className='bookNameUnderImage'>{bookName}</h2>}
      {imageUrl && (
        <img
          src={imageUrl}
          className={className}
          alt={bookName}
          style={style}
          onError={(e) => {
            e.target.style.visibility = 'hidden';
          }}
        />
      )}
    </>
  );
};

export default BookCoverImage;
