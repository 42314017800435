import { IBook } from '../../dataProvider/IBookTable';
import React from 'react';
//import { AddIcon } from '@fluentui/react/react-icons';
//import { Icon } from '@fluentui/react/lib/Icon';
import { PrimaryButton } from '@fluentui/react';
import Footer from '../../components/Footer';
import i18n from '../../i18n';
import { _smallScreen } from '../../SharedCommon/utils';
import BookCategoryHeader from '../common/develop/BookCategoryHeader';
import DevelopAdd from '../common/develop/DevelopAdd';
import { InView } from 'react-intersection-observer';
import BookCoverImage from '../../SharedCommon/BookCoverImage';

/*
https://www.w3schools.com/howto/howto_css_dropdown.asp
/// implement hoverable drop down to do "delete, publish, unpublish, etc...
/// or add delete in the class editing screen, which already have the class state
/// to change to publish, or private, etc.
*/

interface IContentState {
  bookContent?: {};
}

interface IOwnerBooksProps {
  books: IBook[];
  setBook: any; // (any, boolean) :void ;

  addBook: any;
  deleteBook: any;
}

interface IOwnerBooksState {
  contentHeight: string;
}

class OwnerBooks extends React.Component<IOwnerBooksProps, IOwnerBooksState> {
  constructor(props: IOwnerBooksProps) {
    super(props);
    this.state = {
      contentHeight: 'calc(100vh - 50px)'
    };
  }

  private handleAddBook = () => {
    const bookName = i18n.t('authoring.NoName');
    this.props.addBook(bookName);
    return;
  };

  render(): React.ReactNode {
    console.log('OwnerBooks.render');
    //const { name, key } = getSignInInfo();

    //x.addListener(myFunction) // Attach listener function on state changes
    if (_smallScreen) {
      // If media query matches
      return (
        <div className='HomeMatrixBooks'>
          <h3>This functionality only works with big screen / window</h3>
        </div>
      );
    }

    return (
      <>
        <div className='HomeMatrixBooks' style={{ height: this.state.contentHeight }}>
          <BookCategoryHeader key='div1' title={i18n.t('authoring.Unpublished')} />
          <div key='div2' className='ContentV' style={{ height: 'auto' }}>
            {this.props.books.map((book, index) => {
              return (
                <InView key={index + 1000}>
                  {({ inView, ref /*, entry*/ }) => (
                    <div key={index} ref={ref}>
                      {book.status === 0 && (
                        <div
                          key={100 + index}
                          className='FullscreenBookCoverDiv'
                          onClick={() => this.props.setBook(index)}>
                          {inView && (
                            <BookCoverImage
                              className='FullscreenBookCover'
                              bookId={book.name}
                              bookName={book.classNameChs}
                            />
                          )}
                          <div className='dropdown-content-4-book'>
                            <PrimaryButton
                              title='Delete'
                              ariaLabel='Delete'
                              style={{ border: '1px solid black' }}
                              onClick={(ev) => {
                                ev.stopPropagation();
                                this.props.deleteBook(index);
                              }}>
                              Delete
                            </PrimaryButton>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </InView>
              );
            })}
            <DevelopAdd onClick={this.handleAddBook} />
          </div>

          <BookCategoryHeader key='div5' title={i18n.t('authoring.Published')} />

          <div className='ContentV' style={{ height: 'auto' }}>
            {this.props.books.map((book, index) => {
              return (
                <div key={index}>
                  {book.status === 1 && book.classType === 0 && (
                    <div
                      key={1000 + index}
                      className='FullscreenBookCoverDiv'
                      onClick={() => this.props.setBook(index)}>
                      <BookCoverImage className='FullscreenBookCover' bookId={book.name} bookName={book.classNameChs} />
                    </div>
                  )}
                </div>
              );
            })}
            {this.props.books.filter((book) => {
              return book.status === 1 && book.classType === 0;
            }).length === 0 && <p style={{ marginLeft: '15px' }}>None yet</p>}
          </div>

          <BookCategoryHeader key='div3' title={i18n.t('authoring.Limited')} />

          <div className='ContentV' style={{ height: 'auto' }}>
            {this.props.books.map((book, index) => {
              return (
                <div key={index}>
                  {book.status === 1 && book.classType! > 0 && (
                    <div key={200 + index} className='FullscreenBookCoverDiv' onClick={() => this.props.setBook(index)}>
                      <BookCoverImage className='FullscreenBookCover' bookId={book.name} bookName={book.classNameChs} />
                    </div>
                  )}
                </div>
              );
            })}
            {this.props.books.filter((book) => {
              return book.status === 1 && book.classType! > 0;
            }).length === 0 && <p style={{ marginLeft: '15px' }}>None yet</p>}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default OwnerBooks;
