import React, { useState } from 'react';
import { _dataProvider } from '../dataProvider/DataProvider';
import { t } from 'i18next';
import './ClassRegistration.css';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Dialog } from '@fluentui/react/lib/Dialog';
import defaultAvatar from '../img/icon-DefaultProfilePicture.png';

interface IAddContactCardProps {
  userCard: any;
  dismiss: any;
  launchOneOnOneWithFriend: any;
}

const AcceptContactCard: React.FC<IAddContactCardProps> = ({ userCard, dismiss, launchOneOnOneWithFriend }) => {
  const [introduction, setIntroduction] = useState('');

  const uniqueId = userCard[0];
  const name = userCard[1];

  const handleIntroductionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIntroduction(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const result = await _dataProvider.inviteUser(uniqueId, introduction);
      if (result) {
        launchOneOnOneWithFriend(result.userId, introduction, uniqueId);
        dismiss(undefined); // Close the modal upon successful submission
        return;
      }
    } catch (error) {
      console.error('Failed to add contact:', error);
    }
    alert(t('FailedToAddContact'));
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={() => {
        dismiss(undefined);
      }}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: {
          main: {
            borderRadius: '20px'
          }
        }
      }}>
      <div style={{ textAlign: 'center' }}>
        <b style={{ fontSize: '24px', color: 'grey', fontWeight: 'bold' }}>{t('AddFriendScreen.AddFriend')}</b>{' '}
        <div className='avatar-item'>
          <div key={uniqueId} className='avatar-container' style={{ width: '60px', height: '60px' }}>
            <img src={defaultAvatar} alt='' className='avatar-img' />
          </div>
          <div className='avatar-name'>{name}</div>
          <div className='avatar-name'>
            {t('UserID')}: {uniqueId}
          </div>
        </div>
        <div className='input-container'>
          <p>{t('Chatroom.GreetingInput')}</p>
          <textarea id='contactIntroduction' value={introduction} onChange={handleIntroductionChange} />
        </div>
        <DefaultButton
          onClick={handleSubmit}
          styles={{
            root: {
              margin: 'auto',
              marginTop: '20px', // Ensure there's a gap between textarea and button
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '25px'
            }
          }}>
          Ok
        </DefaultButton>
      </div>
    </Dialog>
  );
};

export default AcceptContactCard;
