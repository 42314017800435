/*
dialogue box:
*/
import * as React from 'react';
import { Dialog, DialogType, DialogFooter, IDialogContentStyles } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { SearchBox, ISearchBoxStyles } from '@fluentui/react/lib/SearchBox';
import { useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { _dataProvider } from '../dataProvider/DataProvider';
import { InView } from 'react-intersection-observer';

import { _smallScreen } from '../SharedCommon/utils';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import { IChatMessage } from '../dataProvider/IChatData';
import './Org.css';
import './Dialog.css';
import { ResponsiveMode } from '@fluentui/react';

import { IUser } from '../dataProvider/DataProvider';
import { UserProfile } from '../SharedCommon/UserProfile';
import { IContactItem } from './GroupMgmt';
import AddNewContactWindow from './AddNewContactWindow';
import closeIcon from '../img/close.png';
import backIcon from '../img/icon-Back.png';
import plusIcon from '../img/icon-Plus.png';

import { AvatarImage, GroupImage } from '../SharedCommon/UserAvatarName';

const options: IChoiceGroupOption[] = [
  { key: 'A', text: 'Option A' },
  { key: 'B', text: 'Option B' },
  { key: 'C', text: 'Option C', disabled: true }
];
const modelProps = {
  isBlocking: false,
  styles: { main: { maxWidth: 450, borderRadius: '20px' } }
};

const dialogContentProps = {
  type: DialogType.largeHeader,
  showCloseButton: true,
  title: 'All emails together',
  subText: 'Your Inbox has changed. No longer does it include favorites, it is a singular destination for your emails.'
};

export const DialogLargeHeaderExample: React.FunctionComponent = () => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);

  return (
    <>
      <DefaultButton secondaryText='Opens the Sample Dialog' onClick={toggleHideDialog} text='Open Dialog' />
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}>
        <ChoiceGroup defaultSelectedKey='B' options={options} />
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text='Save' />
          <DefaultButton onClick={toggleHideDialog} text='Cancel' />
        </DialogFooter>
      </Dialog>
    </>
  );
};

const promptExternalLinkProps = {
  type: DialogType.largeHeader,
  showCloseButton: true,
  title: i18n.t('authoring.EnterLink')
  //subText: "subText -- TBD",
};

export interface IPromptExternalLinkProps {
  label: string;
  url: string;
  onExit: any;
  idx: number;
}

export const PromptExternalLink: React.FunctionComponent<IPromptExternalLinkProps> = (props) => {
  const [{ url, label }, setLabelUrl] = useState({
    url: props.url,
    label: props.label
  });
  //let url = props.url;
  //let label = props.label;

  return (
    <Dialog
      hidden={false}
      onDismiss={() => props.onExit(undefined)}
      dialogContentProps={promptExternalLinkProps}
      modalProps={modelProps}>
      <h3 style={{ textAlign: 'left' }}>{i18n.t('authoring.ContentTitle')}</h3>
      <input
        key={i18n.t('authoring.ContentTitle')}
        value={label}
        onChange={(ev) => setLabelUrl({ url, label: ev.target.value })}
        style={{ width: '100%' }}></input>
      <h3 style={{ textAlign: 'left' }}>{i18n.t('authoring.UrlLink')}</h3>
      <input
        key={i18n.t('authoring.UrlLink')}
        value={url}
        onChange={(ev) => setLabelUrl({ url: ev.target.value, label })}
        style={{ width: '100%' }}></input>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            props.onExit(url, label || i18n.t('authoring.ExternalLink'), props.idx);
          }}
          text={i18n.t('authoring.Save')}
        />
        <DefaultButton onClick={() => props.onExit(undefined)} text={i18n.t('authoring.Cancel')} />
      </DialogFooter>
    </Dialog>
  );
};

const promptDocumentLabel = {
  type: DialogType.largeHeader,
  showCloseButton: true,
  title: i18n.t('authoring.EnterTitle')
  //subText: "subText -- TBD",
};

export interface IPromptDocumentLabelProps {
  label: string;
  onExit: any;
  idx: number;
}

export const PromptDocumentLabel: React.FunctionComponent<IPromptDocumentLabelProps> = (props) => {
  const [{ label }, setLabel] = useState({
    label: props.label
  });
  //let url = props.url;
  //let label = props.label;

  return (
    <Dialog
      hidden={false}
      onDismiss={() => props.onExit(undefined)}
      dialogContentProps={promptDocumentLabel}
      modalProps={modelProps}>
      <h3 style={{ textAlign: 'left' }}>{i18n.t('authoring.DocTitle')}</h3>
      <input
        key='Label'
        value={label}
        onChange={(ev) => setLabel({ label: ev.target.value })}
        style={{ width: '100%' }}></input>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            props.onExit(label, props.idx);
          }}
          text={i18n.t('authoring.Save')}
        />
        <DefaultButton onClick={() => props.onExit(props.label, props.idx)} text={i18n.t('authoring.Cancel')} />
      </DialogFooter>
    </Dialog>
  );
};

const promptForScriptProps = {
  type: DialogType.largeHeader,
  showCloseButton: true,
  title: i18n.t('authoring.EnterScript')
  //subText: "subText -- TBD",
};

export interface IPromptForScriptProps {
  jsonTxt: string;
  onExit: any;
  idx: number;
  iii: number;
}

export const PromptForScript: React.FunctionComponent<IPromptForScriptProps> = (props) => {
  const [jsonTxt, setJsonTxt] = useState(props.jsonTxt);
  return (
    <Dialog
      hidden={false}
      onDismiss={() => props.onExit(undefined)}
      dialogContentProps={promptForScriptProps}
      modalProps={modelProps}>
      <textarea
        key='description'
        style={{ width: '100%', resize: 'vertical' }}
        rows={6}
        value={jsonTxt}
        placeholder={i18n.t('authoring.HereEnterScript')}
        onChange={(ev) => setJsonTxt(ev.target.value)}
      />
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            props.onExit(jsonTxt, props.idx, props.iii);
          }}
          text={i18n.t('authoring.Save')}
        />
        <DefaultButton onClick={() => props.onExit(undefined)} text={i18n.t('authoring.Cancel')} />
      </DialogFooter>
    </Dialog>
  );
};

export interface IPromptPickGroupProps {
  pickGroup: any;
}

/*
    this._dataProvider.getGroups().then((groups)=>{
      if (groups) {
        alert("您暂没有加入小组. 请在移动终端, 用这个链接 https://idigest.app/install 下载idigest软件APP, 注册后, 创建小组或加入小组, 就可以有线上小组电话了");
        return;
      }

      /*
      return <DialogLargeHeaderExample/>;
          {!!this.state.externLinkProps && (
            <PromptExternalLink
              url={this.state.externLinkProps.url}
              label={this.state.externLinkProps.label}
              onExit={this.state.externLinkProps.onExit}
              idx={this.state.externLinkProps.idx}
            />
          )}

      //this._dataProvider.getGroups();
const prompt4Group = {
  type: DialogType.largeHeader,
  title: "请选择小组",
  //title: "Please pick a small group by click",
  //subText: "subText -- TBD",
};
*/

export const PromptPickGroup: React.FunctionComponent<IPromptPickGroupProps> = (props) => {
  const [groupList, setGroups] = useState([]);
  const [txt, setTxt] = useState('Loading...');
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();

  const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
      width: '100%',
      maxWidth: '419px',
      margin: '7px 0 10px 0',
      boxSizing: 'border-box',
      backgroundColor: '#f5f5f5',
      border: '1px solid #e0e0e0' // Gray border
    },
    icon: {
      color: '#666666'
    }
  };

  const headerButtonStyles = {
    root: {
      padding: '8px',
      border: 'none',
      background: 'transparent',
      minWidth: '30px',
      height: '30px'
    },
    flexContainer: {
      height: '100%'
    }
  };

  if (!groupList || groupList.length === 0) {
    //_dataProvider.getManagedOrgsAndGroups().then((data: any) => {
    _dataProvider.getUserGroups().then((groups: any) => {
      if (!groups) {
        setTxt('Server error, check network connection, and try again');
        return;
      }
      if (groups.length === 0) {
        setTxt(t('NoGroup'));
      } else {
        setGroups(groups);
      }
    });
  }

  // Filter groups based on search query
  const filteredGroups = groupList.filter((item: any) =>
    (item.Name || item.name).toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dialog
      hidden={false}
      responsiveMode={ResponsiveMode.small}
      onDismiss={() => {
        props.pickGroup(undefined);
        setSearchQuery(''); // Clear search when dialog is dismissed
      }}
      dialogContentProps={{
        showCloseButton: false,
        styles: {
          inner: {
            padding: '0px 25px'
          },
          header: {
            height: '0px'
          },
          content: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            visibility: 'visible !important',
            overflowY: 'scrollable',
            padding: '0 10px'
          }
        }
      }}
      modalProps={{
        isBlocking: true,
        styles: {
          main: {
            borderRadius: '20px',
            minWidth: '300px',
            maxWidth: '500px',
            width: '100%',
            minHeight: '90vh',
            overflow: 'hidden'
          }
        },
        containerClassName: 'PickMeetingDialogue'
      }}>
      <div className='header-section'>
        <div className='button-wrapper right-btn'>
          <DefaultButton
            className='header-button'
            styles={headerButtonStyles}
            onClick={() => {
              props.pickGroup(undefined);
              setSearchQuery(''); // Clear search when dialog is dismissed
            }}>
            <img src={closeIcon} alt='Close' className='button-icon' />
          </DefaultButton>
          <span className='button-label'>{t('Close')}</span>
        </div>
        <h3 style={{ textAlign: 'center', fontWeight: 450 }}>
          {!!groupList && groupList.length > 0 ? t('ChooseGroup') : txt}
        </h3>
        <br></br>
        {groupList.length > 0 && (
          <SearchBox
            styles={searchBoxStyles}
            value={searchQuery}
            placeholder={t('Search')}
            onEscape={() => setSearchQuery('')}
            onChange={(_, value) => setSearchQuery(value || '')}
            onClear={() => setSearchQuery('')}
          />
        )}
      </div>
      <div className='PickMeeting'>
        {!!groupList &&
          filteredGroups.map((item: any) => (
            <InView key={item.groupId + 1000}>
              {({ inView, ref /*, entry*/ }) => (
                <ul key={item.groupId} ref={ref} className='buttonColor' style={{ padding: 0 }}>
                  <DefaultButton
                    className='orgGroupButton'
                    styles={{
                      flexContainer: {
                        justifyContent: 'left',
                        marginLeft: 0
                      },
                      root: {
                        justifyContent: 'flex-start',
                        border: '1px solid #f5f5f5', // Lighter gray border
                        borderRadius: '22px',
                        padding: '10px',
                        height: '42px',
                        width: '100%',
                        fontSize: '15px',
                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)'
                      },
                      rootHovered: {
                        backgroundColor: '#f5f5f5'
                      }
                    }}
                    onClick={() => props.pickGroup(item.groupId)}>
                    {inView && <GroupImage item={item} className='groupSymbol' />}
                    {item.Name || item.name}
                  </DefaultButton>
                </ul>
              )}
            </InView>
          ))}
      </div>
      <div
        style={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          height: '30px',
          background: 'white'
        }}
      />
    </Dialog>
  );
};

export interface IPromptPickFilesInGroupProps {
  groupId: number;
  processFile: (groupId: number, fileId: number) => Promise<void>;
  onDismiss: () => void;
}

export const IPromptPickFilesInGroup: React.FunctionComponent<IPromptPickFilesInGroupProps> = (props) => {
  const [fileList, setFileList] = useState<IChatMessage[] | undefined | null>(undefined);
  const { t } = useTranslation();
  const [dayRange, setDayRange] = useState(36);

  React.useEffect(() => {
    const getFiles = async () => {
      const files = await _dataProvider.getFileListInGroup(props.groupId, dayRange);
      setFileList(files);
    };
    getFiles();
  }, [dayRange]);

  const IDigestLogo = _dataProvider.getLogo();

  const fileName = (name: string) => {
    if (name.startsWith('文件 ') || name.startsWith('音频 ') || name.startsWith('图片 ')) {
      return name.slice(3);
    }
    return name;
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={() => {
        props.onDismiss();
      }}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { borderRadius: '20px' } },
        containerClassName: 'PickGroupDialogue'
      }}>
      <img alt='iDigestApp' className='centeriDigestLogo' src={IDigestLogo} />
      <br></br>
      <br></br>
      <h3 style={{ textAlign: 'center' }}>
        {fileList === undefined
          ? 'Loading...'
          : fileList === null
          ? 'Server error, please try again'
          : fileList.length === 0
          ? t('NoFile')
          : t('ChooseFile')}
      </h3>
      <div className='PickGroup' style={{ marginLeft: 0 }}>
        {!!fileList &&
          fileList.map((item: any, index) => (
            <ul key={index} className='buttonColor' style={{ padding: 0 }}>
              <DefaultButton
                className='orgGroupButton'
                style={{ width: '100%', padding: 4, borderRadius: 0 }}
                styles={{
                  flexContainer: {
                    marginLeft: 8
                  }
                }}
                onClick={() => props.processFile(props.groupId, item.fileId)}>
                {!_smallScreen && (
                  <div style={{ width: '100%' }}>
                    <p style={{ float: 'left' }}>{new Date(item.time * 1000).toLocaleDateString()}</p>
                    <p style={{ float: 'right' }}>{fileName(item.displayName)}</p>
                  </div>
                )}
                {_smallScreen && fileName(item.displayName)}
              </DefaultButton>
            </ul>
          ))}
        {dayRange === 36 && !!fileList && fileList.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <DefaultButton
              className='orgGroupButton'
              style={{ backgroundColor: 'lightgray', marginTop: 25 }}
              styles={{
                flexContainer: {
                  marginLeft: 25
                }
              }}
              onClick={() => setDayRange(178)}>
              {i18n.t('Chatroom.HalfYear')}
            </DefaultButton>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export interface IPromptContactListProps {
  isOpen: boolean;
  onDismiss: () => void;
  contactList: IContactItem[];
  currentUser: IUser;
  launchOneOnOneWithFriend: any;
}

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: {
    width: '100%',
    backgroundColor: '#f5f5f5',
    border: '1px solid #e0e0e0', // Gray border
    borderRadius: '4px',
    marginTop: '15px',
    marginBottom: '0',
    padding: '4px'
  },
  field: {
    backgroundColor: 'transparent'
  },
  icon: {
    color: '#666666'
  }
};

export const PromptContactList: React.FC<IPromptContactListProps> = (props) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showFriend, setShowFriend] = React.useState<IUser>();
  const [showAddNewContact, setShowAddNewContact] = React.useState(false);

  // Filter contacts based on search query
  const filteredContacts = props.contactList.filter((contact) => {
    return !searchQuery || (contact.name || contact.displayName).toLowerCase().includes(searchQuery.toLowerCase());
  });

  // Group contacts
  const groupedContacts = filteredContacts.reduce((groups, contact) => {
    const letter = contact.displayName[0].toUpperCase();
    if (!groups[letter]) {
      groups[letter] = [];
    }
    groups[letter].push(contact);
    return groups;
  }, {} as Record<string, IContactItem[]>);

  const dialogStyles: Partial<IDialogContentStyles> = {
    inner: {
      padding: '0px 25px'
    },
    header: {
      height: '0px'
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      visibility: 'visible !important',
      overflowY: 'scrollable',
      padding: '0 10px'
    }
  };

  const modalProps = {
    isBlocking: false,
    styles: {
      main: {
        borderRadius: '20px',
        minWidth: '500px',
        maxWidth: '500px',
        width: '100%',
        minHeight: '90vh'
      }
    },
    containerClassName: 'PickMeetingDialogue'
  };

  const dialogContentProps = {
    showCloseButton: false,
    styles: dialogStyles
  };

  const handleDismiss = () => {
    setShowFriend(undefined);
    props.onDismiss();
  };

  const headerButtonStyles = {
    root: {
      padding: '8px',
      border: 'none',
      background: 'transparent',
      minWidth: '30px',
      height: '30px'
    },
    flexContainer: {
      height: '100%'
    }
  };

  const renderHeader = () => {
    if (showFriend) {
      return (
        <div className='header-section'>
          <div className='button-wrapper left-btn'>
            <DefaultButton
              className='header-button'
              styles={headerButtonStyles}
              onClick={() => setShowFriend(undefined)}>
              <img src={backIcon} alt='Back' className='button-icon' />
            </DefaultButton>
            <span className='button-label'>{t('Back')}</span>
          </div>
          <div className='button-wrapper right-btn'>
            <DefaultButton className='header-button' styles={headerButtonStyles} onClick={handleDismiss}>
              <img src={closeIcon} alt='Close' className='button-icon' />
            </DefaultButton>
            <span className='button-label'>{t('Close')}</span>
          </div>
        </div>
      );
    }

    return (
      <div className='header-section'>
        <div className='button-wrapper left-btn'>
          <DefaultButton className='header-button' styles={headerButtonStyles} onClick={handleDismiss}>
            <img src={backIcon} alt='Back' className='button-icon' />
          </DefaultButton>
          <span className='button-label'>{t('Back')}</span>
        </div>
        <div className='button-wrapper right-btn'>
          <DefaultButton
            className='header-button'
            styles={headerButtonStyles}
            onClick={() => setShowAddNewContact(true)}>
            <img src={plusIcon} alt='Add Contact' className='button-icon' />
          </DefaultButton>
          <span className='button-label'>{t('AddNewContact')}</span>
        </div>
        <h3 style={{ textAlign: 'center', fontWeight: 600, fontSize: '18px' }}>{t('Contact')}</h3>
        <br></br>
        <SearchBox
          styles={searchBoxStyles}
          placeholder={t('Search')}
          value={searchQuery}
          onChange={(_, value) => setSearchQuery(value || '')}
          onClear={() => setSearchQuery('')}
        />
      </div>
    );
  };

  return (
    <Dialog
      hidden={!props.isOpen}
      responsiveMode={ResponsiveMode.small}
      onDismiss={props.onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}>
      {renderHeader()}
      {!showFriend ? (
        // Contact List View
        <>
          <div className='contact-list' style={{ overflowY: 'auto' }}>
            {Object.keys(groupedContacts).map((letter) => (
              <div key={letter}>
                {groupedContacts[letter]
                  .filter((user) => user.loginId !== props.currentUser.uniqueId)
                  .map((contact, index) => (
                    <InView key={index * 1000}>
                      {({ inView, ref /*, entry*/ }) => (
                        <ul key={index} ref={ref} style={{ listStyle: 'none', padding: 0, margin: '8px 0' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DefaultButton
                              className='buttonFellowship'
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                border: 'none',
                                paddingLeft: 10
                              }}
                              styles={{
                                flexContainer: {
                                  justifyContent: 'left',
                                  marginLeft: 0
                                },
                                root: {
                                  justifyContent: 'flex-start',
                                  padding: '0 20px',
                                  width: '100%',
                                  fontSize: '15px',
                                  color: 'dark grey'
                                },
                                label: {
                                  textAlign: 'left'
                                }
                              }}
                              onClick={() => {
                                const user = contact as IUser;
                                user.tag = _dataProvider.getMyTagForUser(user.userId);
                                user.block = _dataProvider.getMyBlockForUser(user.userId);
                                setShowFriend(user);
                              }}>
                              {inView && <AvatarImage userId={contact.userId} className='groupSymbol' />}
                              {contact.name || contact.displayName}
                            </DefaultButton>
                          </div>
                        </ul>
                      )}
                    </InView>
                  ))}
              </div>
            ))}
          </div>
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              left: 0,
              right: 0,
              height: '30px',
              background: 'white'
            }}
          />
        </>
      ) : (
        // User Profile View
        <div style={{ padding: '0 35px' }}>
          <UserProfile
            user={showFriend}
            updateFrameStyle={{
              marginTop: '20px'
            }}
            launchOneOnOneWithFriend={props.launchOneOnOneWithFriend}
            onDismiss={handleDismiss}
          />
        </div>
      )}
      {showAddNewContact && (
        <AddNewContactWindow
          onClose={() => setShowAddNewContact(false)}
          launchOneOnOneWithFriend={props.launchOneOnOneWithFriend}
        />
      )}
    </Dialog>
  );
};
