//import ReactDOM from "react-dom";
import './index.css';

//import * as serviceWorker from "./serviceWorker";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import { I18nextProvider } from 'react-i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import i18n from './i18n';

const container = document.getElementById('root');
console.log('index.tsx loaded');
const root = createRoot(container!);
//root.render(<App />);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

//we'll support pwa only if path/host is pwa, to not confuse others things under the same domain
const forPwa = window.location.hostname.startsWith('pwa') || window.location.pathname.startsWith('/pwa');
if (forPwa) {
  serviceWorkerRegistration.register();
} else {
  serviceWorkerRegistration.unregister();
}
