/* eslint no-use-before-define: ["error", { "variables": false }] */

import { DEFAULT_PLACEHOLDER, MIN_COMPOSER_HEIGHT } from './Constant';

import Color from './Color';
import PropTypes from 'prop-types';
import React from 'react';

//import { TextInput } from 'react-native-web';

export default class Composer extends React.Component {
  onContentSizeChange(e) {
    const { contentSize } = e.nativeEvent;

    // Support earlier versions of React Native on Android.
    if (!contentSize) {
      return;
    }

    if (
      !this.contentSize ||
      this.contentSize.width !== contentSize.width ||
      this.contentSize.height !== contentSize.height
    ) {
      this.contentSize = contentSize;
      this.props.onInputSizeChanged(this.contentSize);
    }
  }

  onChangeText(tx) {
    /*
    tx.style.overflow-y: hidden;
    tx.style.height = 'auto';
    tx.style.height = tx.scrollHeight + 'px';
    */
    this.props.onTextChanged(tx);
  }

  onKeyDown(e) {
    const { text, onSend } = this.props;
    if (e.keyCode === 13 && e.shiftKey === false) {
      onSend({ text: text.trim() }, true);
      e.preventDefault();
    }
    return false;
  }

  render() {
    return (
      <textarea
        onKeyDown={(e) => this.onKeyDown(e)}
        placeholder={this.props.placeholder}
        onChange={(event) => this.onChangeText(event.target)}
        style={{
          ...styles.textInput,
          ...this.props.textInputStyle,
          overflowY: 'hidden',
          height: this.props.composerHeight - 10
        }}
        value={this.props.text}
        {...this.props.textInputProps}
      />
    );
  }
}

const styles = {
  textInput: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '16px',
    paddingTop: 6,
    paddingBottom: 3,
    outline: 'none',
    border: 'none',
    overflow: 'auto',
    boxShadow: 'none',
    resize: 'none',
    width: '100%'
  }
};

Composer.defaultProps = {
  composerHeight: MIN_COMPOSER_HEIGHT,
  text: '',
  placeholderTextColor: Color.defaultProps,
  placeholder: DEFAULT_PLACEHOLDER,
  textInputProps: null,
  multiline: true,
  textInputStyle: {},
  textInputAutoFocus: false,
  keyboardAppearance: 'default',
  onTextChanged: () => {},
  onInputSizeChanged: () => {}
};

Composer.propTypes = {
  composerHeight: PropTypes.number,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderTextColor: PropTypes.string,
  textInputProps: PropTypes.object,
  onTextChanged: PropTypes.func,
  onInputSizeChanged: PropTypes.func,
  multiline: PropTypes.bool,
  //textInputStyle: TextInput.propTypes.style,
  textInputAutoFocus: PropTypes.bool,
  keyboardAppearance: PropTypes.string
};
