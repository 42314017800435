import React from 'react';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';

interface IDevelopAddProps {
  onClick: () => void;
}

const iconClass = mergeStyles({
  fontSize: 100,
  margin: '25px 25px'
});

const DevelopAdd: React.FC<IDevelopAddProps> = ({ onClick }) => {
  return (
    <div className='FullscreenBookCoverDiv' onClick={onClick}>
      <FontIcon aria-label='Add new book' iconName='Add' className={iconClass} />
    </div>
  );
};

export default DevelopAdd;
