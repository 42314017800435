import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SignInHeader from './SignInHeader';

const SignInHeaderWrapper = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log('signinheader: ', location);
  const promptSignIn = location.pathname === '/join' ? 2 : 0;

  return <SignInHeader {...props} navigate={navigate} promptSignIn={promptSignIn} />;
};

export default SignInHeaderWrapper;
