// this file is to be maintained to be the same as in webidigest
// so that we can maintain the crypto protocol consistency -- test
// only once and work in both. Majority of the code is used in both
// but there are small variance between web and app

import cryptoUtils from './cryptoUtil';

const dataUrlPattern = /data:.*\/.*;base64,/;
const pwdAPIs = ['/user/login', '/user/create', '/user/refreshAccessToken'];
const freeAPIs = ['/home', '/crash', '/user/unregisterDevice'];

// read the file content, construct a file object, and then upload as a file
async function readFileAsync(file, key, toBase64 = false) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve({ data: reader.result, file, key });
    reader.onerror = () => reject({ error: reader.error, file });

    if (toBase64) {
      reader.readAsDataURL(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
}

//maintain this function to be identical in webidigest's customAxios.js
export async function cryptoRequest(config, cryptoProxy) {
  // init a new req object
  const req = {
    method: config.method ?? 'GET',
    path: config.url,
    accessToken: cryptoUtils.getAccessToken()
  };

  // /user/login and /user/create and /user/refreshAccessToken
  if (pwdAPIs.indexOf(config.url ?? '/') !== -1) {
    const reqData = { ...config.data };
    reqData.timestamp = Date.now() / 1000;
    req.body = reqData;

    config.baseURL = cryptoProxy;
    config.headers['Authorization'] = '';

    if (req.path === '/user/refreshAccessToken') {
      const { encrypted, key } = cryptoUtils.encryptPublicWithAccessToken();
      const data1 = cryptoUtils.encryptSymmetric(key, req);
      const data = [data1, encrypted].join('.');
      config.data = { data };
    } else {
      delete req.accessToken;
      config.transformRequest = [
        function (rawData, headers) {
          if (!rawData || (typeof rawData === 'string' && rawData.startsWith('AxiosError'))) {
            return rawData;
          }
          const data = cryptoUtils.encryptPublic(JSON.stringify(req));
          const buf = JSON.stringify({ data });
          headers['content-type'] = 'application/json; charset=utf-8';
          return buf;
        }
      ];
    }
    config.transformResponse = [
      function (data) {
        if (!data) {
          return data;
        }
        if (typeof data === 'string') {
          if (data.startsWith('{"error":') || data.startsWith('AxiosError')) {
            return data;
          }
        }
        if (req.path === '/user/refreshAccessToken') {
          const arr = req.accessToken.split('.');
          reqData.loginId = arr[0];
          reqData.password = arr[1];
        }

        let buf = cryptoUtils.decryptPwd(reqData.loginId, reqData.password, data);
        if (typeof buf !== 'object') {
          buf = JSON.parse(buf);
        }
        return buf;
      }
    ];
    //for debug purpose, path is helpful in the network trace
    //config.url = '/'; // reset to root. No more trace of API call
    return config;
  }

  const token = cryptoUtils.getSessionSecurityToken();

  //free path
  if (!token) {
    if (freeAPIs.indexOf(config.url ?? '/') !== -1) {
      config.baseURL = cryptoProxy;
      return config;
    }
    // some lesson content is for public
    if (config.url?.startsWith('/lesson')) {
      config.baseURL = cryptoProxy;
      return config;
    }
    //since there is no session key, the below crypto won't work, error out
    throw new Error('require authentication!!');
  }

  //all other paths
  // form post for file uploads, change to a regular json call to cryptoproxy for small files
  const rawData = config.data;
  if (rawData instanceof FormData) {
    let bailOut = false;
    const fileReads = [];
    rawData.forEach((value, key) => {
      if (bailOut) {
        return;
      }
      if (value instanceof File) {
        //for files too large, fall back to the https baseUrL
        if (value.size > 1024 * 1024) {
          bailOut = true;
          return;
        }
        fileReads.push(readFileAsync(value, key, true));
      } else if (typeof value === 'object' && value.size !== undefined) {
        console.log('app upload file: ', value);
        const data = {};
        data[key] = value;
        req.fileUpload = data;
        config.data = undefined; // this should prevent it to become body
      } else {
        // cryptoproxy don't support the use of form for non-file-uploadsd
        bailOut = true;
        return;
      }
    });

    if (bailOut) {
      // for large files, we'll fall back to the ssl URL
      console.log('bailout: ', config);
      return config;
    }
    if (fileReads.length > 0) {
      try {
        const data = {};
        const results = await Promise.all(fileReads);
        results.forEach((it) => {
          data[it.key] = {
            data: it.data.replace(dataUrlPattern, ''),
            filename: it.file.name,
            type: it.file.type,
            lastModified: it.file.lastModified
          };
        });
        req.fileUpload = data;
        config.data = undefined; // this should prevent it to become body
      } catch (error) {
        console.error(error);
        // for large files, we'll fall back to the ssl URL
        return config;
      }
    }
  }

  if (config.responseType) {
    req.responseType = config.responseType;
  }
  config.headers['Authorization'] = 'Bearer ' + token;
  config.baseURL = cryptoProxy;
  config.method = 'POST'; // it's always post to the cryptoproxy

  //for debug purpose, path is helpful in the network trace
  //config.url = '/'; // reset to root. No more trace of API call

  config.transformRequest = [
    function (rawData, headers) {
      //console.log('transformRequest:', typeof rawData, rawData);
      req.body = rawData;
      const data = cryptoUtils.encryptSession(JSON.stringify(req));
      const buf = JSON.stringify({ data });
      headers['content-type'] = 'application/json; charset=utf-8';
      return buf;
    }
  ];
  config.transformResponse = [
    function (data) {
      if (!data) {
        return data;
      }
      if (typeof data === 'string') {
        if (data.startsWith('{"error":') || data.startsWith('AxiosError')) {
          return data;
        }
      }

      if (req.responseType === 'blob') {
        return data;
      }
      let buf = cryptoUtils.decryptSession(data, true);
      if (typeof buf !== 'object') {
        buf = JSON.parse(buf);
      }
      return buf;
    }
  ];
  return config;
}
