import React, { useState, useEffect, useRef } from 'react';
import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import { DefaultButton, DatePicker } from '@fluentui/react';
import { Dialog, IDialogContentStyles, DialogFooter } from '@fluentui/react/lib/Dialog';
import './GroupMemberTool.css';
import { IContactItem } from './GroupMgmt';
import { IGroupUpdateInfo } from '../dataProvider/IClassManagement';
import { userAvatarName } from '../SharedCommon/UserAvatarName';
import { UserProfile } from '../SharedCommon/UserProfile';
import i18n from '../i18n';
import ChecklistContainer from './CheckListContainer';
import { SERVER_HOST } from '../SharedCommon/utils';
import './Org.css';
import './Dialog.css';
import { ResponsiveMode } from '@fluentui/react';
import closeIcon from '../img/close.png';
import backIcon from '../img/icon-Back.png';
import plusIcon from '../img/icon-plus-hover-over-state.png';
import sendIcon from '../img/icon-Post.png';
import { QRCodeSVG } from 'qrcode.react';

enum DisplayMode {
  AllMembersMode = 'allMembersMode',
  AddMembersMode = 'addMembersMode',
  DisplayMemberMode = 'displayMemberMode'
}

interface IGroupMemberToolProps {
  groupId: number;
  isGroupLeader: boolean;
  show: boolean;
  onClose: () => void;
  launchOneOnOneWithFriend: any;
  contactList: IContactItem[];
}

interface IStudentProgressHandler {
  [lessonName: string]: {
    display: boolean;
    studentInfo: { [studentId: number]: number };
  };
}

export interface IAttendanceRecord {
  date: string; // ISO 8601 format date
  users: IUser[]; // Array of user IDs who attended
  allUsers: IUser[]; // Array of all user IDs in the group
  formattedDate: string; // Date in MM/DD/YY format
  percentageAttendance: number; // users.length divided by allUsers.length
}

interface IStudentAttendanceHandler {
  [formattedDate: string]: IAttendanceRecord;
}

interface IGenAttendanceBoxProps {
  onClick: any; // Use onClick as type any
}

const GroupMemberTool: React.FC<IGroupMemberToolProps> = ({
  groupId,
  isGroupLeader,
  show,
  onClose,
  launchOneOnOneWithFriend,
  contactList
}) => {
  const [groupInfo, setGroupInfo] = useState<any>(null);
  const [users, setUsers] = useState<IUser[]>([]);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.AllMembersMode);
  // the user that is being displayed
  const [selectedUser, setSelectedUser] = useState<IUser>();
  // users that can be added to the group by you
  const [otherUsers, setOtherUsers] = useState<IUser[]>([]);
  // for adding, an array of loginId
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [joinRequireLeaderApproval, setJoinRequireLeaderApproval] = useState(0);
  const [classResourceId, setClassResourceId] = useState(-1);
  // for the book information that we need to show in the modal
  const [selectedBookInfo, setSelectedBookInfo] = useState<any>(null);
  // for whether or not we show the book's chapters
  const [showBookChapters, setShowBookChapters] = useState(false);
  // this is for the progress popup
  const [showProgressModal, setShowProgressModal] = useState(false);
  // this is for the attendance popup
  const [showAttendanceModal, setAttendanceModal] = useState(false);
  // for tracking student progresses and whether or not we display
  // calling studentProgressHandler.Display give a boolean
  // calling studenProgressHandler.Progress gives a mapping
  const [studentProgressHandler, setStudentProgressHandler] = useState<IStudentProgressHandler>({});
  // for attendance information
  const [attendanceInfo, setAttendanceInfo] = useState<IStudentAttendanceHandler>({});
  // which date has been selected, if not selected, it is null
  const [selectedDateInfo, setSelectedDateInfo] = useState<IAttendanceRecord | null>(null);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDatePicker, setSelectedDatePicker] = useState<Date>(new Date());

  useEffect(() => {
    if (show) {
      getCurrentGroup();
    }
  }, [show]);

  // this is for change group name box
  const classNameInputRef = useRef<HTMLInputElement>(null);
  // for box showing notice
  const classNoticeRef = useRef<HTMLInputElement>(null);
  // for join meeting password
  const classMeetingPwd = useRef('');

  async function generateIUser(otherUser: any): Promise<IUser> {
    // Assuming otherUser has properties like displayName, userId, and loginId
    const { displayName, userId, loginId } = otherUser;

    // Create IUser object
    const user: IUser = {
      userId: parseInt(userId, 10), // Ensure userId is converted to number
      name: otherUser.name,
      displayName: displayName,
      loginId: loginId,
      isGroupLeader: false
    };

    return user;
  }

  const getCurrentGroup = async () => {
    try {
      const groupData = await _dataProvider.getGroupInfo(groupId);
      //  change the domain to be host domain!!!
      //  groupData.joinLink
      //  groupData.meetingLink
      if (groupData.joinLink) {
        groupData.joinLink = groupData.joinLink.replace(/:\/\/.+\//, `://${SERVER_HOST}/`);
      }
      if (groupData.meetingLink) {
        groupData.meetingLink = groupData.meetingLink.replace(/:\/\/.+\//, `://${SERVER_HOST}/`);
      }
      console.log('groupInfo:', groupData);
      setJoinRequireLeaderApproval(groupData.joinRequireLeaderApproval);
      const filteredUsers = contactList.filter(
        (user) => !groupData.users.some((groupUser: IUser) => groupUser.userId === user.userId)
      );
      console.log('other users:', contactList);
      // convert from Icontactitem to Iuser
      const convertedOtherUsers = await Promise.all(filteredUsers.map((user) => generateIUser(user)));
      setOtherUsers(convertedOtherUsers);
      // Check if each user has an avatar
      const usersWithAvatars = groupData.users.map(
        (user: { name: string; userId: string; loginId: string; isGroupLeader: boolean; accepted: boolean }) => {
          return {
            userId: user.userId,
            loginId: user.loginId,
            isGroupLeader: user.isGroupLeader,
            displayName: user.name,
            accepted: user.accepted
          };
        }
      );
      setGroupInfo({ ...groupData, users: usersWithAvatars });
      setUsers(usersWithAvatars);
    } catch (error) {
      console.error('Error fetching group info:', error);
    }
  };

  // set the mode to display user mode
  const displayUserProfile = (user: IUser) => {
    setDisplayMode(DisplayMode.DisplayMemberMode);
    user.tag = _dataProvider.getMyTagForUser(user.userId);
    user.block = _dataProvider.getMyBlockForUser(user.userId);
    setSelectedUser(user);
  };

  const handleClose = () => {
    onClose();
    setDisplayMode(DisplayMode.AllMembersMode);
    setSelectedUsers([]);
  };

  const handleUpdate = async () => {
    console.log('update called with users', selectedUsers);
    // Use the selectedUsers state to get the list of user login IDs
    const loginIds = selectedUsers;

    // Add users to group
    try {
      // Map each login ID to the addUserToGroup promise
      const addUserPromises = loginIds.map((loginId) => _dataProvider.addUserToGroup(groupId, loginId));
      const results = await Promise.all(addUserPromises);

      // Process successful results
      let updatedOtherUsers = otherUsers;
      results.forEach((result, index) => {
        if (result === 'success') {
          const loginId = loginIds[index];
          const successfullyAddedUser = otherUsers.find((user) => user.loginId.toString() === loginId);
          if (successfullyAddedUser) {
            // Remove from otherUsers
            updatedOtherUsers = updatedOtherUsers.filter((user) => user !== successfullyAddedUser);
            setOtherUsers(updatedOtherUsers);
            successfullyAddedUser.accepted = true;
            // put newly added user to the front
            setUsers((prevUsers) => [successfullyAddedUser, ...prevUsers]);
          }
        } else {
          alert(i18n.t('Failed to add user with loginId') + ': ' + loginIds[index]);
        }
      });
      setSelectedUsers([]);
      setDisplayMode(DisplayMode.AllMembersMode);
    } catch (error) {
      console.error('Error adding users to group:', error);
      alert(i18n.t('Failed to add users to group'));
    }
  };

  const handleKickMember = async (user: IUser) => {
    // Implement the logic to kick the member
    console.log(`Kicking member: ${user.displayName}`);
    const studentId = user.userId;
    const response = await _dataProvider.deleteFromGroup(groupId, studentId);
    if (response === 'success') {
      //alert(i18n.t('Member kicked successfully'));
      // Remove the kicked member from the 'users' state
      setUsers((prevUsers) => prevUsers.filter((u) => u.userId !== user.userId));
      // Add the kicked member to the 'otherUsers' state
      setOtherUsers((prevOtherUsers) => [...prevOtherUsers, user]);
      setDisplayMode(DisplayMode.AllMembersMode);
    } else {
      alert(i18n.t('Failed to kick member'));
    }
  };

  const handleDemoteMember = async (user: IUser) => {
    if (!user.isGroupLeader) {
      alert(user.displayName + i18n.t(' is not an admin'));
      return;
    }
    const studentId = user.userId;
    console.log(`Demoting member: ${user.displayName}`);
    const response = await _dataProvider.demoteMember(groupId, studentId);
    if (response === 'success') {
      //alert(i18n.t('Member Demoted successfully'));
      // Make member into normal privelege
      setUsers((prevUsers) =>
        prevUsers.map((u) => {
          if (u.userId === user.userId) {
            return { ...u, isGroupLeader: false };
          }
          return u;
        })
      );
      setDisplayMode(DisplayMode.AllMembersMode);
    } else {
      alert(i18n.t('Failed to demote member'));
    }
  };

  const handlePromoteMember = async (user: IUser) => {
    const userInGroup = users.some((u) => u.userId === user.userId);
    // check if user is in the group
    if (!userInGroup) {
      alert(i18n.t('The student is not currently in the group'));
      return;
    }
    // Check if the user is already an admin
    if (user.isGroupLeader) {
      alert(user.displayName + i18n.t(' is already an admin'));
      return;
    }
    const studentId = user.userId;
    console.log(`Promoting member: ${user.displayName}`);
    const response = await _dataProvider.promoteMember(groupId, studentId);
    if (response === 'success') {
      //alert(i18n.t('Member Promoted successfully'));
      // Make member into admin
      setUsers((prevUsers) =>
        prevUsers.map((u) => {
          if (u.userId === user.userId) {
            return { ...u, isGroupLeader: true };
          }
          return u;
        })
      );
      setDisplayMode(DisplayMode.AllMembersMode);
    } else {
      alert(i18n.t('Failed to promote member'));
    }
  };

  const requestTriageAsync = async (user: any, approved: boolean) => {
    const response = await _dataProvider.respondToRequest(groupId, user.userId, approved);
    if (response) {
      if (approved) {
        user.accepted = true;
        const newUsers = [user, ...users.filter((uu) => uu.userId !== user.userId)];
        setUsers(newUsers);
      } else {
        setUsers((prevUsers) => prevUsers.filter((u) => u.userId !== user.userId));
        setOtherUsers((prevOtherUsers) => [...prevOtherUsers, user]);
      }
      setDisplayMode(DisplayMode.AllMembersMode);
    }
  };

  const renderSelectedUserContainer = (user: IUser | undefined) => {
    if (!user) {
      return null; // Render nothing if user is undefined
    }
    console.log('Selected user', user);

    return (
      <div className='selected-user-container'>
        <br />
        <UserProfile
          user={user}
          updateFrameStyle={{ border: '1px solid', padding: 10, marginTop: 10 }}
          onDismiss={onClose}
          launchOneOnOneWithFriend={launchOneOnOneWithFriend}
        />
        <br />
        {isGroupLeader && user.accepted && (
          <div className='button-container'>
            <DefaultButton style={{ padding: 0 }} onClick={() => handleKickMember(user)}>
              {i18n.t('KickMember')}
            </DefaultButton>
            <DefaultButton
              style={{ padding: 0 }}
              onClick={() => (user.isGroupLeader ? handleDemoteMember(user) : handlePromoteMember(user))}>
              {user.isGroupLeader ? i18n.t('DemoteMember') : i18n.t('PromoteMember')}
            </DefaultButton>
          </div>
        )}
        {isGroupLeader && !user.accepted && (
          <div className='button-container'>
            <DefaultButton style={{ padding: 0 }} onClick={() => requestTriageAsync(user, true)}>
              {i18n.t('GroupScreen.Approve')}
            </DefaultButton>
            <DefaultButton style={{ padding: 0 }} onClick={() => requestTriageAsync(user, false)}>
              {i18n.t('GroupScreen.Reject')}
            </DefaultButton>
          </div>
        )}
      </div>
    );
  };

  const renderAddMembersContainer = () => {
    return (
      <ChecklistContainer
        otherUsers={otherUsers}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        handleUpdate={handleUpdate}
        addMemberButtonText={i18n.t('Update')}
      />
    );
  };

  // for users to copy to clipboard (invitation link or call link)
  const copyLinkToClipboard = async (linkToCopy: string) => {
    try {
      await navigator.clipboard.writeText(linkToCopy);
      alert('Link copied to clipboard!');
    } catch (error) {
      console.error('Error copying link:', error);
    }
  };

  const changeGroupName = async () => {
    const newClassName = classNameInputRef.current?.value;
    const notice = groupInfo.notice;

    if (!newClassName) {
      alert('Class name cannot be empty');
      return;
    }

    const groupUpdateInfo: IGroupUpdateInfo = {
      groupId: groupId,
      name: newClassName,
      notice,
      meetingPassword: null,
      disappearingMessages: null
    };

    const result = await _dataProvider.updateGroupInfo(groupUpdateInfo);

    if (result === 'success') {
      setGroupInfo((prev: any) => ({
        ...prev,
        name: newClassName
      }));
      alert('Group name updated successfully');
    } else {
      alert('Failed to update group name');
    }
  };
  const ChangeNotice = async () => {
    alert(`New notice: ${classNoticeRef.current?.value}`);
    // make call to backend to change class name
    const groupName = groupInfo.name;
    const notice = classNoticeRef.current?.value;
    const groupUpdateInfo: IGroupUpdateInfo = {
      groupId,
      name: groupName as string,
      notice: notice as string,
      meetingPassword: null,
      disappearingMessages: null
    };
    console.log('updating name with', groupUpdateInfo);

    const result = await _dataProvider.updateGroupInfo(groupUpdateInfo);

    if (result === 'success') {
      console.log('Notice updated successfully');
    } else {
      console.log('Failed to update Notice');
      alert('Failed to update notice');
    }
  };

  const handleZoomPwdChange = (event: any) => {
    classMeetingPwd.current = event.target.value;
  };

  const openProgressModal = () => {
    setShowProgressModal(true);
  };

  const openAttendanceModal = async () => {
    if (showAttendanceModal == false) {
      try {
        const attendanceRes = await _dataProvider.getSpecificGroupAttendance(groupId);
        console.log('attendance from backend returned', attendanceRes);
        const formattedAttendance: IStudentAttendanceHandler = attendanceRes.attendance.reduce(
          (acc: IStudentAttendanceHandler, record: any) => {
            const formattedDate =
              record.date.substring(5, 7) + '/' + record.date.substring(8, 10) + '/' + record.date.substring(2, 4);
            const percentageAttendance = (record.users.length / record.allUsers.length) * 100;
            const users = record.users.map((userId: number) => attendanceRes.users[userId]);
            const allUsers = record.allUsers.map((userId: number) => attendanceRes.users[userId]);
            acc[formattedDate] = {
              date: record.date,
              users: users,
              allUsers: allUsers,
              formattedDate: formattedDate,
              percentageAttendance: percentageAttendance
            };
            return acc;
          },
          {}
        );
        // Convert formattedAttendance object into an array of entries
        const formattedAttendanceEntries = Object.entries(formattedAttendance);

        // Sort the array of entries by date (assuming formattedDate is in MM/DD/YY format)
        formattedAttendanceEntries.sort(([dateA], [dateB]) => {
          const timestampA = new Date(dateA).getTime();
          const timestampB = new Date(dateB).getTime();
          return timestampA - timestampB;
        });

        // Convert the sorted array of entries back into an object
        const sortedFormattedAttendance: IStudentAttendanceHandler = Object.fromEntries(formattedAttendanceEntries);
        setAttendanceInfo(sortedFormattedAttendance);
        console.log('attendance:', sortedFormattedAttendance);
        setAttendanceModal(true);
      } catch (error) {
        console.error('Failed to fetch attendance:', error);
      }
    } else {
      setAttendanceModal(false);
    }
  };

  const handleAttendanceBoxClick = (date: string) => {
    console.log(`Clicked on date: ${date}`);
    setSelectedDateInfo(attendanceInfo[date]);
    setSelectedDate(date);
  };

  const handleNewAttendanceBoxClick = () => {
    console.log('new attendance box clicked');
    const today = new Date();
    // signal to render attended students to turn into addition mode
    const newAttendanceRecord: IAttendanceRecord = {
      date: '',
      users: [],
      allUsers: groupInfo.users,
      formattedDate: '',
      percentageAttendance: 0
    };
    // Set selectedDateInfo to the new attendance record
    setSelectedDateInfo(newAttendanceRecord);
    setSelectedDate('new');
    setSelectedDatePicker(today);
  };

  // special box for adding a brand new date
  const GenAttendanceBox: React.FC<IGenAttendanceBoxProps> = ({ onClick }) => (
    <div className='attendance-box special' onClick={onClick} style={{ cursor: 'pointer' }}>
      <img src={plusIcon} alt='Add' style={{ width: '100%' }} />
    </div>
  );
  // shows little boxes of the dates where attendance was recorded
  const renderAttendanceGrid = () => (
    <div className='attendance-grid'>
      {Object.keys(attendanceInfo).map((date) => (
        <div
          key={date}
          className={`attendance-box ${selectedDate === date ? 'selected' : ''}`}
          onClick={() => handleAttendanceBoxClick(date)}
          style={{ cursor: 'pointer' }}>
          <small>{date}</small>
          <strong>{attendanceInfo[date].percentageAttendance.toFixed(1)}%</strong>
        </div>
      ))}
      <GenAttendanceBox onClick={handleNewAttendanceBoxClick} />
    </div>
  );

  // for book progress
  const setSelectedClass = async (input: number) => {
    if (showBookChapters && selectedBookInfo?.lessonId == input.toString()) {
      setShowBookChapters(false);
    } else {
      setClassResourceId(input);
      console.log(classResourceId);
      console.log('pressed');
      // get the book's chapters
      const result = await _dataProvider.getBookTable(input.toString());
      setSelectedBookInfo(result);
      // get the progress for the students
      const progresses = await _dataProvider.getGroupStudyProgress(groupId, input);
      console.log('progresses:', progresses);
      // update the progress handler, set all display options to false
      console.log('book info:', result);
      // build the progress display
      const updatedProgressHandler: IStudentProgressHandler = {};
      progresses.forEach((chapter: any) => {
        updatedProgressHandler[chapter.title] = {
          display: false,
          studentInfo: {}
        };
        chapter.users.forEach((user: any) => {
          updatedProgressHandler[chapter.title].studentInfo[user.userId] = user.progress;
        });
      });
      console.log('student progresses:', updatedProgressHandler);
      setStudentProgressHandler(updatedProgressHandler);
      console.log(studentProgressHandler);
      setShowBookChapters(true);
    }
  };

  // When you want to show a specific chapter's progresses
  const handleClickChapter = (title: string) => {
    setStudentProgressHandler((prevState) => ({
      ...prevState,
      [title]: {
        ...prevState[title],
        display: !prevState[title]?.display
      }
    }));
  };

  // for finding the student name from id
  // check groupinfo.users.id equivalent to what we provided
  const findStudentNameFromId = (id: number): string => {
    const user = groupInfo.users.find((user: any) => user.userId === id);
    return user ? user.name : ''; // Return the user's name if found, otherwise an empty string
  };

  const handleBackClick = () => {
    setSelectedDate(null);
    setSelectedDateInfo(null);
    setShowDatePicker(false);
    setSelectedDatePicker(new Date());
  };

  const handleCheckboxChange = (userId: number) => {
    if (selectedDateInfo) {
      setSelectedDateInfo((prevInfo) => {
        const updatedUsers = prevInfo!.users.some((user) => user.userId === userId)
          ? prevInfo!.users.filter((user) => user.userId !== userId)
          : [...prevInfo!.users, prevInfo!.allUsers.find((user) => user.userId === userId)!];
        return {
          ...prevInfo!,
          users: updatedUsers
        };
      });
    }
  };

  // for date picker change
  const handlePickerDateChange = (date: Date | null | undefined): void => {
    if (!date) {
      return;
    }

    const isoStringWithTime = date.toISOString().slice(0, 11) + '08:00:00.000Z';
    if (selectedDateInfo) {
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
      setSelectedDateInfo({
        ...selectedDateInfo,
        date: isoStringWithTime,
        formattedDate: formattedDate
      });
      setSelectedDatePicker(date);
    }
  };

  const updateAttendanceRecord = async () => {
    console.log('updating attendace with', selectedDateInfo);
    try {
      // Call setAttendance function with attendanceRecord and groupId
      const success = await _dataProvider.setAttendance(selectedDateInfo as IAttendanceRecord, groupId);
      if (success) {
        alert('Successfully updated attendance.');
        // set or update the info, attendance info
        const selectedRecord = selectedDateInfo as IAttendanceRecord;
        // Calculate the percentage attendance
        const percentageAttendance = (selectedRecord.users.length / selectedRecord.allUsers.length) * 100;
        // Update selectedRecord with the calculated percentage
        const updatedRecord: IAttendanceRecord = {
          ...selectedRecord,
          percentageAttendance: percentageAttendance
        };
        // Set the updated selectedDateInfo state
        setSelectedDateInfo(updatedRecord);
        // Update attendanceInfo state
        setAttendanceInfo((prevAttendanceInfo) => {
          return {
            ...prevAttendanceInfo,
            [updatedRecord.formattedDate]: updatedRecord
          };
        });
      } else {
        alert('Failed to update attendance.');
      }
    } catch (error) {
      console.error('Error updating attendance:', error);
      alert('An error occurred while updating attendance.');
    }
  };

  // Render the attended students list
  const renderAttendedStudents = () => {
    if (!selectedDateInfo) {
      return null;
    }
    return (
      <div>
        <DefaultButton className='header-button' styles={headerButtonStyles} onClick={handleBackClick}>
          <img src={backIcon} alt='Back' className='button-icon' />
        </DefaultButton>
        <h3 style={{ textAlign: 'center' }}>
          {selectedDate === 'new'
            ? i18n.t('Create New Attendance Record')
            : `${i18n.t('Attended Students for')} ${selectedDate}`}
        </h3>
        {selectedDate === 'new' && (
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
              <DefaultButton onClick={() => setShowDatePicker(true)}>{i18n.t('SelectDate')}</DefaultButton>
            </div>
            {showDatePicker && (
              <DatePicker
                value={selectedDatePicker}
                onSelectDate={handlePickerDateChange}
                formatDate={(date?: Date): string => {
                  if (!date) {
                    return '';
                  }
                  return date.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit'
                  });
                }}
                styles={{
                  root: {
                    minWidth: '200px'
                  }
                }}
                placeholder='Select a date...'
                ariaLabel='Select a date'
              />
            )}
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div>
            {selectedDateInfo.allUsers.map((user) => (
              <div key={user.userId} className='attended-student'>
                <label>
                  {user.name || user.displayName} ({user.userId})
                </label>
                <div className='checkbox-container'>
                  <input
                    type='checkbox'
                    checked={selectedDateInfo.users.some((u) => u.userId === user.userId)}
                    onChange={() => handleCheckboxChange(user.userId)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <DefaultButton onClick={updateAttendanceRecord}>{i18n.t('Update')}</DefaultButton>
          </div>
        </div>
      </div>
    );
  };

  const handleCloseProgressModal = () => {
    setShowProgressModal(false);
    setShowBookChapters(false);
  };

  const handleCloseAttendanceModal = () => {
    setAttendanceModal(false);
    setSelectedDateInfo(null);
    setSelectedDate(null);
    setShowDatePicker(false);
    setSelectedDatePicker(new Date());
  };

  const dialogStyles: Partial<IDialogContentStyles> = {
    inner: {
      padding: '0px 25px'
    },
    header: {
      height: '0px'
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      visibility: 'visible !important',
      overflowY: 'scrollable',
      padding: '0 10px'
    }
  };

  const modalProps = {
    isBlocking: false,
    styles: {
      main: {
        borderRadius: '20px',
        minWidth: '500px',
        maxWidth: '500px',
        width: '100%',
        minHeight: '90vh'
      }
    },
    containerClassName: 'PickMeetingDialogue'
  };

  const dialogContentProps = {
    showCloseButton: false,
    styles: dialogStyles
  };

  const headerButtonStyles = {
    root: {
      padding: '8px',
      border: 'none',
      background: 'transparent',
      minWidth: '30px',
      height: '30px'
    },
    flexContainer: {
      height: '100%'
    }
  };

  const renderHeader = () => {
    if (displayMode === DisplayMode.AddMembersMode) {
      return (
        <div className='header-section'>
          <div className='button-wrapper left-btn'>
            <DefaultButton
              className='header-button'
              styles={headerButtonStyles}
              onClick={() => setDisplayMode(DisplayMode.AllMembersMode)}>
              <img src={backIcon} alt='Back' className='button-icon' />
            </DefaultButton>
            <span className='button-label'>{i18n.t('Back')}</span>
          </div>
          <div className='button-wrapper right-btn'>
            <DefaultButton className='header-button' styles={headerButtonStyles}>
              {/* Icon needs to be changed to "OK" and onClick needed*/}
              <img src={sendIcon} alt='Confirm' className='button-icon' />
            </DefaultButton>
            <span className='button-label'>{i18n.t('Confirm')}</span>
          </div>
          <h3 style={{ textAlign: 'center', fontWeight: 600, fontSize: '18px' }}>{i18n.t('AddGroupMembers')}</h3>
          <br />
        </div>
      );
    }

    return (
      <div className='header-section'>
        <div className='button-wrapper left-btn'>
          <DefaultButton
            className='header-button'
            styles={headerButtonStyles}
            onClick={() => {
              if (displayMode === DisplayMode.DisplayMemberMode) {
                setDisplayMode(DisplayMode.AllMembersMode);
                return;
              }
              handleClose();
            }}>
            <img src={backIcon} alt='Back' className='button-icon' />
          </DefaultButton>
          <span className='button-label'>{i18n.t('Back')}</span>
        </div>
        <div className='button-wrapper right-btn'>
          <DefaultButton className='header-button' styles={headerButtonStyles} onClick={handleClose}>
            <img src={closeIcon} alt='Close' className='button-icon' />
          </DefaultButton>
          <span className='button-label'>{i18n.t('Close')}</span>
        </div>
        <h3 style={{ textAlign: 'center', fontWeight: 600, fontSize: '18px' }}>{i18n.t('ManageGroup')}</h3>
        <br />
      </div>
    );
  };

  return (
    <Dialog
      hidden={false}
      responsiveMode={ResponsiveMode.small}
      onDismiss={handleClose}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}>
      <div className='PickGroup'>
        {renderHeader()}
        {displayMode === DisplayMode.AllMembersMode && <h2>{groupInfo ? groupInfo.name : 'Loading...'}</h2>}
        <br />
        <div>
          {displayMode === 'allMembersMode' && groupInfo && (
            <>
              {isGroupLeader && users.filter((user) => !user.accepted).length > 0 && (
                <>
                  <h3>{i18n.t('GroupScreen.WaitingForApproval')}</h3>
                  <div className='member-container'>
                    {users
                      .filter((user) => !user.accepted)
                      .map((user) => (
                        <div key={user.userId} className='avatar-wrapper' onClick={() => displayUserProfile(user)}>
                          {userAvatarName(user, 1, false, '40px', 10)}
                        </div>
                      ))}
                  </div>
                </>
              )}
              <h3>{i18n.t('Group Leaders')}</h3>
              <div className='member-container'>
                {users
                  .filter((user) => user.isGroupLeader)
                  .map((user) => (
                    <div key={user.userId} className='avatar-wrapper' onClick={() => displayUserProfile(user)}>
                      {userAvatarName(user, 1, false, '40px', 10)}
                    </div>
                  ))}
              </div>
              <h3>{i18n.t('Member')}</h3>
              <div className='member-container'>
                {users
                  .filter((user) => !user.isGroupLeader && user.accepted)
                  .map((user) => (
                    <div key={user.userId} className='avatar-wrapper' onClick={() => displayUserProfile(user)}>
                      {userAvatarName(user, 1, false, '40px', 10)}
                    </div>
                  ))}
                {isGroupLeader && (
                  <div className='add-member-wrapper'>
                    <DefaultButton
                      className='add-member-button'
                      onClick={() => setDisplayMode(DisplayMode.AddMembersMode)}>
                      <img src={plusIcon} alt='Add Member' />
                    </DefaultButton>
                    <span className='add-member-label'>{i18n.t('AddGroupMembers')}</span>
                  </div>
                )}
              </div>
              {isGroupLeader && (
                <>
                  <div className='infoBox'>
                    <div className='infoLabel'>{i18n.t('SmallGroupName')}</div>
                    <div className='inputBox'>
                      <input
                        ref={classNameInputRef}
                        className='infoValue editableInput'
                        type='text'
                        defaultValue={groupInfo.name}
                      />
                    </div>
                    <DefaultButton onClick={changeGroupName}>{i18n.t('Update')}</DefaultButton>
                  </div>
                  <div className='infoBox'>
                    <div className='infoLabel'>{i18n.t('ShareGroup')}</div>
                    <div className='infoValue'>
                      <a href={groupInfo.joinLink} target='_blank' rel='noopener noreferrer'>
                        {groupInfo.joinLink}
                      </a>
                    </div>
                    <div style={{ padding: '10px 0' }}>
                      <QRCodeSVG value={groupInfo.joinLink} size={128} level='L' />
                    </div>
                    <DefaultButton onClick={() => copyLinkToClipboard(groupInfo.joinLink)}>
                      {i18n.t('Share')}
                    </DefaultButton>
                    <br />
                    <h4>
                      <input
                        type={'checkbox'}
                        checked={joinRequireLeaderApproval === 1}
                        onChange={async () => {
                          const newStatus = joinRequireLeaderApproval == 0 ? 1 : 0;
                          const result = await _dataProvider.updateGroup(groupId, {
                            joinRequireLeaderApproval: newStatus
                          });
                          if (result) {
                            setJoinRequireLeaderApproval(newStatus);
                          } else {
                            //setJoinRequireLeaderApproval(joinRequireLeaderApproval);
                            console.log('Failed to save: updateGroupInfoAsync');
                          }
                        }}
                      />
                      <span>{i18n.t('GroupScreen.RequireLeaderApproval')}</span>
                    </h4>
                  </div>
                  <div className='infoBox'>
                    <div className='infoLabel'>{i18n.t('OnlineMeeting')}</div>
                    <div className='infoValue'>
                      <div>{groupInfo.meetingLink}</div>
                      <div>{i18n.t('Password')}</div>
                      <div className='inputBox'>
                        <input
                          onChange={handleZoomPwdChange}
                          className='infoValue editableInput'
                          type='text'
                          defaultValue={groupInfo.meetingPassword}
                        />
                      </div>
                      <div className='meeting-btn-container'>
                        <DefaultButton onClick={() => copyLinkToClipboard(groupInfo.meetingLink)}>
                          {i18n.t('CopyMeetingInfo')}
                        </DefaultButton>
                        <DefaultButton
                          onClick={() =>
                            window.open(
                              `${groupInfo.meetingLink}${
                                classMeetingPwd.current ? `?pwd=${classMeetingPwd.current}` : ''
                              }`,
                              '_blank',
                              'noopener,noreferrer'
                            )
                          }>
                          {i18n.t('JoinMeeting')}
                        </DefaultButton>
                      </div>
                    </div>
                  </div>
                  <div className='infoBox'>
                    <div className='infoLabel'>{i18n.t('Functions')}</div>
                    <div className='func-btn-container'>
                      <DefaultButton onClick={openProgressModal}>{i18n.t('Progress')}</DefaultButton>
                      <DefaultButton onClick={openAttendanceModal}>{i18n.t('Attendance')}</DefaultButton>
                    </div>
                    <Dialog
                      hidden={!showProgressModal}
                      onDismiss={handleCloseProgressModal}
                      dialogContentProps={{ title: i18n.t('Progress') }}>
                      <div className='buttonBox'>
                        {groupInfo.classes.map((classItem: any) => (
                          <div
                            key={classItem.resourceId}
                            className='modalBookButton'
                            onClick={() => setSelectedClass(classItem.resourceId)}>
                            <img
                              src={_dataProvider.getBookCover(classItem.resourceId)}
                              alt={`Book ${classItem.resourceId}`}
                            />
                          </div>
                        ))}
                      </div>
                      {/* Conditional rendering based on showBookChapters */}
                      {showBookChapters && selectedBookInfo && (
                        <div className='sessionBox'>
                          {selectedBookInfo.sessions.map((session: any) => (
                            <div key={session.lesson}>
                              <DefaultButton className='wideButton' onClick={() => handleClickChapter(session.title)}>
                                <p>{session.title}</p>
                              </DefaultButton>
                              {studentProgressHandler[session.title]?.display && (
                                <div>
                                  <ul>
                                    {Object.entries(studentProgressHandler[session.title]?.studentInfo).map(
                                      ([studentId, value]) => (
                                        <li key={studentId}>
                                          {findStudentNameFromId(parseInt(studentId, 10))}: {value}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                      <DialogFooter>
                        <DefaultButton onClick={handleCloseProgressModal} text={i18n.t('Close')} />
                      </DialogFooter>
                    </Dialog>
                    <Dialog
                      hidden={!showAttendanceModal}
                      onDismiss={handleCloseAttendanceModal}
                      dialogContentProps={{ title: i18n.t('Attendance') }}>
                      <div>{selectedDate ? renderAttendedStudents() : renderAttendanceGrid()}</div>
                      <DialogFooter>
                        <DefaultButton onClick={handleCloseAttendanceModal} text={i18n.t('Close')} />
                      </DialogFooter>
                    </Dialog>
                  </div>
                  <div className='infoBox'>
                    <div className='infoLabel'>{i18n.t('Notice')}</div>
                    <div className='inputBox'>
                      <input
                        ref={classNoticeRef}
                        className='infoValue editableInput'
                        type='text'
                        defaultValue={groupInfo.notice}
                      />
                    </div>
                    <DefaultButton onClick={ChangeNotice}>{i18n.t('Post')}</DefaultButton>
                  </div>
                  <div className='infoBox'>
                    <div className='infoLabel'>{i18n.t('Books')}</div>
                    <div className='imageContainer'>
                      {groupInfo.classes.map((classItem: any) => (
                        <img
                          key={classItem.resourceId}
                          src={_dataProvider.getBookCover(classItem.resourceId)}
                          alt={`Book ${classItem.resourceId}`}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {displayMode === DisplayMode.AddMembersMode && renderAddMembersContainer()}
          {displayMode === 'displayMemberMode' && selectedUser && renderSelectedUserContainer(selectedUser)}
        </div>
      </div>
    </Dialog>
  );
};

export default GroupMemberTool;
