// ts-ignore-next-line
import io from 'socket.io-client';
import { cryptoProxyHost, useCryptoProxy, getToken, SERVER_HOST } from '../SharedCommon/utils';
import cryptoUtils from './cryptoproxy/cryptoUtil';

// this socket is for the whole app, not to a specific chatroom
export default class GTAppSocket {
  //static
  socket = null;
  // eslint-disable-next-line
  newMessageCallback = (data) => {};
  // eslint-disable-next-line
  connectedCallback = () => {};
  disconnectedCallback = () => {};
  reconnectedCallback = () => {};

  constructor() {}

  init({ onNewMessage }) {
    if (!getToken()) {
      console.log('no init app socket');
      return;
    }
    if (window.location.href.indexOf('appsocket=1') === -1) {
      console.log('app socket can be enabled with ?appsocket=1');
      return;
    }
    console.log('init app socket');

    this.newMessageCallback = onNewMessage;

    this.closeSocket();

    const host = useCryptoProxy ? cryptoProxyHost : SERVER_HOST;
    console.log('websocket:', useCryptoProxy, cryptoProxyHost, host);
    const sss = io(host, {
      transports: ['websocket'], // use WebSocket first, if available
      reconnection: true,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 30000
    });
    console.log('app socket created : ', this.socket, sss);
    if (window.___appsocket) {
      //in strict mode, code are run twice
      //two runs will both queued at io() call
      //when io call returns, it needs to close the last one
      //to avoid side effects of two socket connections
      // kill the last one, let the latest one to win
      window.___appsocket.close();
      //this.closeSocket();
    }
    window.___appsocket = sss;
    this.socket = sss;

    this.socket.on('connect', () => {
      console.log(`app new socket: [${this.socket.id}]connected!`);
      this.onConnected();
      this.connectedCallback();
    });

    this.socket.on('disconnect', (reason) => {
      console.log(`app socket [${this.socket.id}]disconnect! ${reason}`);
      this.disconnectedCallback();
    });

    this.socket.on('reconnect', (error) => {
      console.log(`app socket  [${this.socket.id}]reconnect!`, error);
      this.reconnectedCallback();
    });

    this.socket.on('error', (error) => {
      // no need to show since we will reconnect
      console.log('app socket error:', error);
    });

    this.socket.on('connect_error', (error) => {
      console.log('app socket error:', error);
      // revert back to polling
      // this.socket.io.opts.transports = ['polling'];
      // this.socket.io.opts.upgrade = false;
    });

    this.socket.on('connect_timeout', (timeout) => {
      // no need to show since we will reconnect
      console.log(`app socket connect_timeout: ${timeout}`);
    });

    this.socket.on('reconnect_error', (error) => {
      console.log('app socket error: ', error);
    });

    this.socket.on('reconnect_failed', () => {
      alert('reconnect_failed');
    });

    this.socket.on('notifyMessage', (data) => {
      console.log('app socket notifyMessage', this.socket.id, data);
      if (useCryptoProxy) {
        data = JSON.parse(cryptoUtils.decryptSession(data));
      }
      this.onNotifyMessage(data);
    });
  }

  onConnected() {
    if (useCryptoProxy) {
      try {
        const data = cryptoUtils.encryptSession({ accessToken: getToken() });
        if (!data) {
          console.log('app socket onConnected: invalid1');
          return;
        }
        console.log('app socket onConnected emit auth: ', getToken());
        this.socket.emit('auth', [cryptoUtils.getSessionSecurityToken(), data].join('.'));
      } catch (error) {
        console.log('onConnected error: ', error);
        return;
      }
    } else {
      console.log('app socket onConnected emit auth: ', getToken());
      this.socket.emit('auth', {
        accessToken: getToken()
      });
    }
  }

  onNotifyMessage(data) {
    console.log('app socket onNotifyMessage: ', data);
    try {
      this.newMessageCallback(data);
    } catch (error) {
      console.log('onNewMessage: ', error, data);
      return;
    }
  }

  async sendMessageAsync(data) {
    console.log('app socket sendMessage:', data);
    //const result = await globalThis.dsObject.sendMessage(data);
    //return result ? true : false;
  }

  closeSocket() {
    if (this.socket) {
      this.socket.close();
    }
    this.socket = null;
  }
}
