import IDigestLogo0 from '../img/splash0.png';
import IDigestLogo1 from '../img/splash.png';
import IDigestLogo2 from '../img/EnYan.jpeg';
//import IDigestLogo3 from '../img/Boteli.jpeg';
import { SERVER_URL } from '../SharedCommon/utils';

const orgList = [
  {
    logo: IDigestLogo0,
    prefix: 'www',
    orgId: 0
  },
  {
    orgId: 1,
    classProgressionControl: true
  },
  {
    logo: IDigestLogo1,
    prefix: 'xwa',
    orgId: 11,
    orgServerUrl: SERVER_URL + '/api-xwa'
  },
  {
    logo: IDigestLogo2,
    prefix: 'enyan',
    orgId: 12,
    classProgressionControl: true
  }
  /* boteli is not using it..., so give this domain back to general use
  ,
  {
    logo: IDigestLogo3,
    prefix: 'boteli',
    orgId: 13,
    classProgressionControl: true
  }
  */
];

export function getOrgServer(id: number) {
  if (!id) {
    return undefined;
  }

  const org = orgList.find((org) => org.orgId === id);
  if (!org) {
    return undefined;
  }
  return org.orgServerUrl;
}

export function getOrgClassProgressionControl(id: number) {
  const org = orgList.find((org) => org.orgId === id);
  if (!org) {
    return false;
  }
  return !!org.classProgressionControl;
}

export function getOrgLogo(id: number) {
  const org = orgList.find((org) => org.orgId === id);
  if (!org) {
    // if the org images is not yet defined, default to iDigest
    return orgList[0].logo;
  }
  return org.logo;
}

export function getOrgIdFromUrl() {
  const params = new URLSearchParams(window.location.search);
  const hostname = window.location.hostname;
  const testOrgParam = params.get('testorg');
  if (testOrgParam) {
    const testOrgId = parseInt(testOrgParam, 10);
    if (!isNaN(testOrgId)) {
      return testOrgId;
    }
  }

  const org = orgList.find(
    (org) => !!org.prefix && (hostname.startsWith(org.prefix) || params.get(org.prefix) === '1')
  );
  return org ? org.orgId : 0;
}
