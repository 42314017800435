/* eslint no-use-before-define: ["error", { "variables": false }] */

import { StyleSheet, Text, TouchableOpacity, View } from 'react-native-web';

import Color from './Color';
import PropTypes from 'prop-types';
import React from 'react';

export default class Actions extends React.Component {
  constructor(props) {
    super(props);
    this.onActionsPress = this.onActionsPress.bind(this);
    this.state = {
      isHovered: false
    };
  }

  onActionsPress() {
    /* GT -- the context doesn't work
    const { options } = this.props;
    const optionKeys = Object.keys(options);
    const cancelButtonIndex = optionKeys.indexOf('Cancel');

    this.context.actionSheet().showActionSheetWithOptions(
      {
        options: optionKeys,
        cancelButtonIndex,
        tintColor: this.props.optionTintColor
      },
      (buttonIndex) => {
        const key = optionKeys[buttonIndex];
        if (key) {
          options[key](this.props);
        }
      }
    );
    */
  }

  renderIcon() {
    const { renderActionIcon, renderActionHoverIcon } = this.props;
    const { isHovered } = this.state;

    if (renderActionIcon) {
      return (
        <View
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}>
          {isHovered && renderActionHoverIcon ? renderActionHoverIcon() : renderActionIcon()}
        </View>
      );
    }

    return (
      <View style={[styles.wrapper, this.props.wrapperStyle]}>
        <Text style={[styles.iconText, this.props.iconTextStyle]}>+</Text>
      </View>
    );
  }

  render() {
    const { label } = this.props;
    return (
      <View style={[styles.container, this.props.containerStyle]}>
        <TouchableOpacity style={styles.touchable} onPress={this.props.onPressActionButton || this.onActionsPress}>
          {this.renderIcon()}
        </TouchableOpacity>
        {label && <Text style={styles.label}>{label}</Text>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: 26,
    height: 26,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  touchable: {
    width: 26,
    height: 26
  },
  wrapper: {
    borderRadius: 13,
    borderColor: Color.defaultColor,
    borderWidth: 2,
    flex: 1
  },
  iconText: {
    color: Color.defaultColor,
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: Color.backgroundTransparent,
    textAlign: 'center'
  },
  label: {
    fontSize: 9,
    color: 'black',
    marginTop: 2,
    textAlign: 'center',
    whiteSpace: 'nowrap'
  }
});

Actions.contextTypes = {
  actionSheet: PropTypes.func
};

Actions.defaultProps = {
  onSend: () => {},
  options: {},
  optionTintColor: Color.optionTintColor,
  renderActionIcon: null,
  renderActionHoverIcon: null,
  icon: null,
  containerStyle: {},
  iconTextStyle: {},
  wrapperStyle: {},
  label: null
};

Actions.propTypes = {
  onSend: PropTypes.func,
  options: PropTypes.object,
  optionTintColor: PropTypes.string,
  renderActionIcon: PropTypes.func,
  renderActionHoverIcon: PropTypes.func,
  icon: PropTypes.func,
  onPressActionButton: PropTypes.func,
  label: PropTypes.string
  /*
  wrapperStyle: ViewPropTypes.style,
  containerStyle: ViewPropTypes.style,
*/
  //iconTextStyle: Text.propTypes.style,
};
